import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../Styles/Register.css";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { registerCompany, registerspvvCompany } from "../redux/action/companyaction";
import Sidebar from "./Sidebar/Sidebar";

const AddSpvv = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);

  const back = () => {
    navigate("/profile");
  };
  
  const nexthandler = () => {
    navigate(`/addDirectors/${companyId}`);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("spvvname", name);
    formData.append("spvvemail", email);
    formData.append("spvvcompanylogo", logo);
    
    try {
      const success = await dispatch(registerspvvCompany(formData, companyId));
      if (success === "true") {
        navigate(`/addDirectors/${companyId}`);
      }
    } catch (error) {
      console.error("Error registering SPVV company:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  return (
    <div className="all">
      <Sidebar/>
      <div className="container" style={{width:"70vw"}}>
        <h3>Register SPVV Company</h3>
        <hr />
        <Form onSubmit={submithandler}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Company Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Company Logo</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <button className="privews" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="warning"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            Add
          </button>
          <button className="privews" onClick={nexthandler}>
            Next
          </button>
        </Form>
      </div>
    </div>
  );
};

export default AddSpvv;
