import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  loading: false,
  error: null,
  message: null,
};
export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("loginRequest", (state) => {
      state.loading = true;
    })
    .addCase("loginSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.message = action.payload.message;
    })
    .addCase("loginFail", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase("companyloginRequest", (state) => {
      state.loading = true;
    })
    .addCase("companyloginSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.message = action.payload.message;
    })
    .addCase("companyloginFail", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase("logoutRequest", (state) => {
      state.loading = true;
    })
    .addCase("logoutSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.message = action.payload;
    })
    .addCase("logoutFail", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = action.payload;
    })
    .addCase("getAllUserRequest", (state) => {
      state.loading = true;
    })
    .addCase("getAllUserSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("getAllUserFail", (state, action) => {
      state.loading = true;
      state.message = action.payload;
    })
    .addCase("loadUserRequest", (state) => {
      state.loading = true;
    })
    .addCase("loadUserSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("loadUserFail", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase("deleteUserRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteUserSuccess", (state, action) => {
      state.loading = false;
      state.user = action.payload;
    })
    .addCase("deleteUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("registerRequest", (state) => {
      state.loading = true;
    })
    .addCase("registerSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("registerFail", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase("uploadDocsRequest", (state) => {
      state.loading = true;
    })
    .addCase("uploadDocsSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("uploadDocsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("uploadmyDocsRequest", (state) => {
      state.loading = true;
    })
    .addCase("uploadmyDocsSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("uploadmyDocsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteDocRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteDocSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("deleteDocFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteMyDocRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteMyDocSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("deleteMyDocFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("deleteCompanyDocRequest", (state) => {
      state.loading = true;
    })
    .addCase("deleteCompanyDocSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("deleteCompanyDocFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("forgetPAsswordRequest", (state) => {
      state.loading = true;
    })
    .addCase("ForgetPasswordSuccess", (state, action) => {
      state.loading = false;
      state.user = action.payload;
    })
    .addCase("ForgetPasswordFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("ResetPAsswordRequest", (state) => {
      state.loading = true;
    })
    .addCase("ResetPasswordSuccess", (state, action) => {
      state.loading = false;
      state.user = action.payload;
    })
    .addCase("ResetPasswordFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("updateUserRoleRequest", (state) => {
      state.loading = true;
    })
    .addCase("updateUserRoleSuccess", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    })
    .addCase("updateUserRoleFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("searchRequest", (state) => {
      state.loading = true;
    })
    .addCase("searchSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("searchFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("RegisterCompanyRequest", (state) => {
      state.loading = true;
    })
    .addCase("RegisteCompanySuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("RegisterCompanyFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("RegisterspvvCompanyRequest", (state) => {
      state.loading = true;
    })
    .addCase("RegistesrpvvCompanySuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("RegisterspvvCompanyFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("AddDirectorsRequest", (state) => {
      state.loading = true;
    })
    .addCase("AddDirectorsSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("AddDirectorsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("AddNomineesRequest", (state) => {
      state.loading = true;
    })
    .addCase("AddNomineesSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("AddNomineesFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UploadSpvDocumentsRequest", (state) => {
      state.loading = true;
    })
    .addCase("UploadSpvDocumentsSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("UploadSpvDocumentsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UploadDocumentsRequest", (state) => {
      state.loading = true;
    })
    .addCase("UploadDocumentsSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("UploadDocumentsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("AddContractRequest", (state) => {
      state.loading = true;
    })
    .addCase("AddContractSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("AddContractFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("getSingleUSer", (state) => {
      state.loading = true;
    })
    .addCase("singleUserSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("getSingleUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("clearError", (state) => {
      state.error = null;
    })
    .addCase("clearMessage", (state) => {
      state.message = null;
    });
});





// const images = [

//   /* Add other images */
  
// ];