import toast from "react-hot-toast";
import { server } from "../store";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
export const login = (email, password, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "loginRequest" });

    const { data } = await axios.post(
      `${server}/login`,
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    localStorage.setItem("authToken", data.token); // Store token in localStorage
    setTimeout(() => {
      localStorage.removeItem("resetEmail"); // Remove email from localStorage after 20 minutes
    }, 30 * 60 * 1000);

    dispatch({ type: "loginSuccess", payload: data });
    navigate("/admin/sendotp", { state: { email } });
    toast.success(data.message);
  } catch (error) {
    dispatch({ type: "loginFail", payload: error.response.data.message });
    toast.error(
      error.response.data.message || "Login failed. Please try again."
    );
  }
};

export const AdminSendOtp = (email, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "OtpSendRequest" });

    const { data } = await axios.post(
      `${server}/admin/otp`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "OtpSendSuccess", payload: data });
    navigate("/adminAuthenticate/email", { state: { email } }); // Pass phoneNumber in the state
    toast.success(`Otp is sent to the Admin Please Ask For it.`);
  } catch (error) {
    dispatch({
      type: "OtpSendFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Login failed. Please try again."
    );
  }
};

export const AdminAuthenticateCompanyOtp =
  (email, otp, deviceInfo, navigate) => async (dispatch) => {
    try {
      dispatch({ type: "AdminOtpAuthenticateRequest" });

      const { data } = await axios.post(
        `${server}/admin/verifyOtp`,
        { email, otp, deviceInfo },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      localStorage.setItem("authToken", data.token); // Store token in localStorage
      setTimeout(() => {
        localStorage.removeItem("resetEmail"); // Remove email from localStorage after 20 minutes
      }, 20 * 60 * 1000);

      dispatch({ type: "AdminOtpAuthenticateSuccess", payload: data });
      toast.success(data.message);
      navigate("/admin/profile"); // Navigate after successful OTP authentication
    } catch (error) {
      dispatch({
        type: "AdminOtpAuthenticateFail",
        payload: error.response?.data?.message || "Authentication failed",
      });
      toast.error(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

export const loadUser = () => async (dispatch) => {
  // try {
  //   dispatch({ type: "loadUserRequest" });
  //   const { data } = await axios.get(`${server}/me`, {
  //     withCredentials: true,
  //   });
  //   dispatch({ type: "loadUserSuccess", payload: data.user });
  //   console.log(data, "lalalal");
  // } catch (error) {
  //   dispatch({ type: "loadUserFail", payload: error.response.data.message });
  // }
};

export const logout = () => (dispatch) => {
  try {
    localStorage.removeItem("authToken"); // Clear token from localStorage
    dispatch({ type: "logoutSuccess" });
    toast.success("Logged Out Successfully");
  } catch (error) {
    dispatch({ type: "logoutFail", payload: error.message });
    toast.error("Logout failed. Please try again.");
  }
};

export const getAllUser = (query) => async (dispatch) => {
  try {
    dispatch({ type: "getAllUserRequest" });

    const token = getAuthToken();

    const { data } = await axios.get(
      `${server}/admin/getallUser/query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "getAllUserSuccess", payload: data.user });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "getAllUserFail",
      payload: error.response.data.message,
    });
  }
};

export const deleteUSer = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteUserRequest" });
    const token = getAuthToken();

    const { data } = await axios.delete(`${server}/deleteUser/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "deleteUserSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteUserFail",
      payload: error.response.data.message,
    });
  }
};
export const register = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "registerRequest" });

    const { data } = await axios.post(
      `${server}/create`, 
      formData, // Pass formData directly, not inside an object
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set correct content type for FormData
        },
        withCredentials: true,
      }
    );

    localStorage.setItem("authToken", data.token); // Store token in localStorage

    // Optionally, remove resetEmail from localStorage after 20 minutes
    setTimeout(() => {
      localStorage.removeItem("resetEmail");
    }, 20 * 60 * 1000);

    toast.success(data.message); // Show success toast message
    dispatch({ type: "registerSuccess", payload: data });
  } catch (error) {
    toast.error(error.response.data.message); // Show error toast message
    dispatch({ type: "registerFail", payload: error.response.data.message });
  }
};

export const uploadDocs = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "uploadDocsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/admin/uploaddocs`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "uploadDocsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "uploadDocsFail",
      payload: error.response.data.message,
    });
  }
};

export const deleteSingleDoc = (UserId, DocId) => async (dispatch) => {
  try {
    dispatch({ type: "deleteDocRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(
      `${server}/deleteDoc?userId=${UserId}&docsId=${DocId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "deleteDocSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteDocFail",
      payload: error.response.data.message,
    });
  }
};

export const getAllCompany =
  (email = "", keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "getAllCompanyRequest" });
      const token = getAuthToken();
      const { data } = await axios.get(
        `${server}/admin/getallcompany?keyword=${keyword}&email=${email}`,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      dispatch({ type: "getAllCompanySuccess", payload: data.user });
    } catch (error) {
      dispatch({
        type: "getAllCompanyFail",
        payload: error.response.data.message,
      });
    }
  };

export const deleteSingleDocCompany = (UserId, DocId) => async (dispatch) => {
  try {
    dispatch({ type: "deleteCompanyDocRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(
      `${server}/deleteDoc?userId=${UserId}&docsId=${DocId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    dispatch({ type: "deleteCompanyDocSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteCompanyDocFail",
      payload: error.response.data.message,
    });
  }
};

export const forgetpassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: "forgetPAsswordRequest" });
    const { data } = await axios.post(
      `${server}/forgetpassword`,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: "ForgetPasswordSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ForgetPasswordFail",
      payload: error.response.data.message,
    });
  }
};

export const resetPassword =
  (email, otp, password, confirmPassowrd) => async (dispatch) => {
    try {
      dispatch({ type: "ResetPAsswordRequest" });
      const { data } = await axios.post(
        `${server}/resetpassword`,
        {
          email,
          otp,
          password,
          confirmPassowrd,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({ type: "ResetPasswordSuccess", payload: data.message });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "RestPasswordFail",
        payload: error.response.data.message,
      });
    }
  };
export const updateRole = (id) => async (dispatch) => {
  try {
    dispatch({ type: "updateUserRoleRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(
      `${server}/role/${id}`,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    dispatch({ type: "updateUserRoleSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "updateUserRoleFail",
      payload: error.response.data.message,
    });
  }
};

export const uploadmyDocs = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "uploadmyDocsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/upload `, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "uploadmyDocsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "uploadmyDocsFail",
      payload: error.response.data.message,
    });
  }
};

export const deleteMyDoc = (DocId) => async (dispatch) => {
  try {
    dispatch({ type: "deleteMyDocRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(
      `${server}/deletemyDoc?docsId=${DocId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "deleteMyDocSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteMyDocFail",
      payload: error.response.data.message,
    });
  }
};

export const searchUser = (queryParams) => async (dispatch) => {
  try {
    dispatch({ type: "searchRequest" });

    const token = getAuthToken();
    const queryString = new URLSearchParams(queryParams).toString();

    const { data } = await axios.get(`${server}/search?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: "searchSuccess", payload: data.users });

    if (data.users.length > 0) {
      toast.success("Search results found.");
    } else {
      toast.error("No results found.");
    }
  } catch (error) {
    dispatch({
      type: "searchFail",
      payload: error.response.data.message,
    });
    toast.error("Error: No results found.");
  }
};
export const searchNDa = (queryParams) => async (dispatch) => {
  try {
    dispatch({ type: "searchRequest" });

    const token = getAuthToken();
    const queryString = new URLSearchParams(queryParams).toString();

    const { data } = await axios.get(`${server}/searchNda?${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: "searchSuccess", payload: data.users });

    if (data.users.length > 0) {
      toast.success("Search results found.");
    } else {
      toast.error("No results found.");
    }
  } catch (error) {
    dispatch({
      type: "searchFail",
      payload: error.response.data.message,
    });
    toast.error("Error: No results found.");
  }
};

export const singleuser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSingleUSer" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getsingleuser/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "singleUserSuccess", payload: data.users });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "getSingleUserFail",
      payload: error.response.data.message,
    });
  }
};
export const singlenda = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSingleNda" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getsingleNda/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "singleNdaSuccess", payload: data.users });
    return data.users;
  } catch (error) {
    console.log(error);
    dispatch({
      type: "getSingleNdaFail",
      payload: error.response.data.message,
    });
  }
};

export const RegisterCompany = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "RegisterCompanyRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/registerCompany`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "RegisterCompanySuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "RegisterCompanyFail",
      payload: error.response.data.message,
    });
  }
};
