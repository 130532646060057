import React from "react";
import "../Events/Modal.css";
import { ImCross } from "react-icons/im";

const NotificationModal = ({ show, onClose, onSubmit, loading }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <ImCross className="close-button" onClick={onClose} />

        {loading ? (
          <div className="spinner"></div> // Show spinner while loading
        ) : (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="file">File:</label>
              <input type="file" id="file" name="file" required />
            </div>
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input type="text" id="title" name="title" required />
            </div>
            <button type="submit" className="submit-button" style={{ backgroundColor: "#007bff" }}>
              Create Notification
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
