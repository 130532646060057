
import React, { useState } from "react";
import Header from "../Header";
import { Form } from "react-bootstrap";
import Footer from "../Footer/Footer";
import "./HelpDesk.css";
import { useDispatch } from "react-redux";
import { helpdesk } from "../../redux/action/companyaction";
import { Link } from "react-router-dom";

const GeneralHelpdesk = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(helpdesk(name, email, contact, query));
    // Handle form submission, for example, send data to an API
    console.log("Form submitted:", { name, email, contact, query });

    // Reset form fields
    setName("");
    setEmail("");
    setContact("");
    setQuery("");
  };

  return (
    <>
      <div className="helpall">
        <div className="helpForm">
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formContact">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact No."
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formQuery">
              <Form.Label>Query</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Brief your query here"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                required
              />
            </Form.Group>
            <button className="helpbutton" type="submit">
              Send
            </button>
            <p> If you want to send an email, contact us at{' '}
              <a href="mailto:WebSupportT1@vpvvindia.com">
                WebSupportT1@vpvvindia.com
              </a></p>
          </Form>
        </div>
      </div>
      <hr style={{ margin: "0" }} />
      <Footer />
    </>
  );
};

export default GeneralHelpdesk;
