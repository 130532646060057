import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { getGuest } from '../../redux/action/companyaction';
import { Link, useParams } from 'react-router-dom';

const AdminGuest = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [guests, setGuests] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getGuest(id));
      if (response && response.length > 0) {
        const extractedGuests = response.flatMap(event =>
          event.guest.map(g => ({
            id: g._id,
            name: g.Name,  // Use `Name` with a capital `N`
            age: g.Age,    // Use `Age` with a capital `A`
            clientName: event.Company?.name || 'N/A',
            document: g.document.url,
          }))
        );
        setGuests(extractedGuests);
      }
    };
    
    fetchData(); // Call fetchData instead of metho
    
  }, [dispatch, id]);
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuests = guests.filter((guest) =>
    guest.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(filteredGuests);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Guests');

    XLSX.writeFile(wb, 'guests_data.xlsx');
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ padding: '10px', width: 'calc(100% - 120px)', boxSizing: 'border-box', borderRadius: "13px" }}
        />
        <button
          onClick={handleDownload}
          style={{ padding: '10px 20px', borderRadius: '13px', border: 'none', backgroundColor: '#4CAF50', color: 'white', cursor: 'pointer' }}
        >
          Download
        </button>
      </div>

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>S.No</th>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>ID</th>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>Guest Name</th>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>Age</th>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>Company Name</th>
            <th style={{ border: '2px solid #ddd', padding: '8px' }}>Document</th>
          </tr>
        </thead>
        <tbody>
          {filteredGuests.length > 0 ? (
            filteredGuests.map((guest,index) => (
              <tr key={guest.id}>
                <td style={{ border: '2px solid #ddd', padding: '8px' }}>{index+1}</td>
                <td style={{ border: '2px solid #ddd', padding: '8px' }}>{guest.id}</td>
                <td style={{ border: '2px solid #ddd', padding: '8px' }}>{guest.name}</td>
                <td style={{ border: '2px solid #ddd', padding: '8px' }}>{guest.age}</td>
                <td style={{ border: '2px solid #ddd', padding: '8px' }}>{guest.clientName}</td>
                    <td style={{ border: '2px solid #ddd', padding: '8px' }}><Link to={guest.document}>{`Document${index +1}` }</Link> </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: 'center', padding: '8px' }}>No guests found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminGuest;
