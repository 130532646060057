import React, { useState } from "react";
import "../../../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateParentDocuments } from "../../../redux/action/companyaction";

const UpdateDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [certificate, setCertificate] = useState(null);
  const [companyPan, setCompanyPan] = useState(null);
  const [companyTan, setCompanyTan] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (setter) => (e) => {
    setter(e.target.files[0]);
    console.log("File selected:", e.target.files[0]); // Debug log to ensure files are selected
  };

  const submithandler = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("certificate", certificate);
    formData.append("companyPan", companyPan);
    formData.append("companyTan", companyTan);

    // Log form data for debugging
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    dispatch(updateParentDocuments(formData, id))
      .then(() => {
        // Reset form fields after successful upload
        setCertificate(null);
        setCompanyPan(null);
        setCompanyTan(null);
        navigate("/dashboard");  // Navigate to the desired page
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error uploading data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="all">
      <div className="container" style={{ width: "70vw" }}>
        <h3>Update Documents (Parent Company)</h3>
        <hr />
        <Form onSubmit={submithandler}>
          <Form.Group controlId="formFile1" className="mb-3">
            <Form.Label>Incorporation Certificate</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange(setCertificate)}
            />
          </Form.Group>

          <Form.Group controlId="formFile2" className="mb-3">
            <Form.Label>Company PAN</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setCompanyPan)} />
          </Form.Group>

          <Form.Group controlId="formFile3" className="mb-3">
            <Form.Label>Company TAN</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setCompanyTan)} />
          </Form.Group>

          <button className="privews" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            Update
          </button>
        </Form>
      </div>
    </div>
  );
};

export default UpdateDocument;
