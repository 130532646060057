import React, { useState, useEffect } from "react";
import "./Otp.css";
import logo from "../logo-vpvv.png";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthenticateCompanyOtp } from "../../redux/action/companyaction";

const AuthenticateOtp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timer]);

  const submitHandler = async (e) => {
    e.preventDefault();
    await dispatch(AuthenticateCompanyOtp(email, otp, navigate));
  };

  const   resendOtpHandler = () => {
    setTimer(60);
    setIsButtonDisabled(true);
    // Logic to resend OTP
  };

  return (
    <div className="full">
      <img style={{width:"13rem"}} src={logo} alt="logo" />
      <div className="box">
        <Row className="justify-content-center">
          <Col xs={10} md={6} className="containeraa">
            <h1 className="text-center mt-3">OTP Authentication</h1>
            <Form onSubmit={submitHandler}>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={otp}
                  placeholder="One Time Password"
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="otp">
                <button className="mt-4" type="submit">
                  Submit
                </button>
              </div>

              <div className="mt-3">
                <Button
                  onClick={resendOtpHandler}
                  disabled={isButtonDisabled}
                  className="resend-otp-btn"
                >
                  Resend OTP
                </Button>
                {isButtonDisabled && <span className="timer"> {timer}s</span>}
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AuthenticateOtp;
