import React from "react";
import "./Modal.css";
import { ImCross } from "react-icons/im";

const Modal = ({ show, onClose, onSubmit, loading }) => {
  if (!show) return null;

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.file.files[0]);  // Ensure this matches the backend's field name
    formData.append("date", e.target.date.value);
    formData.append("title", e.target.title.value);

    onSubmit(e, formData);  // Pass formData to the onSubmit function
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <ImCross className="close-button" onClick={onClose} />

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="file">File:</label>
            <input type="file" id="file" name="file" required />
          </div>
          <div className="form-group">
            <label htmlFor="date">Date:</label>
            <input type="date" id="date" name="date" required />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input type="text" id="title" name="title" required />
          </div>
          <button
            type="submit"
            className="submit-button"
            style={{ backgroundColor: "#007bff" }}
            disabled={loading}
          >
            {loading ? <span className="spinner"></span> : "Create Event"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
