import React, { useState } from "react";
import "./profile.css";
import { IoMdSearch } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { searchUser } from "../../redux/action/useravtion";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users = [], error, message, loading } = useSelector((state) => state.user);

  const [searchTerms, setSearchTerms] = useState({
    name: "",
    spvvName: "",
    clientName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchTerms((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchUser(searchTerms));
  };

  const handleUserClick = (id, e) => {
    e.preventDefault();
    navigate(`/user/${id}`);
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <main style={{ flex: 1 }}>
        <div className="search">
          <form onSubmit={handleSearch} className="search-bar">
            <FaUserCircle size={25} />
            <input
              type="text"
              placeholder="Search By Company Name"
              name="name"
              value={searchTerms.name}
              onChange={handleInputChange}
            />
            <button type="submit">
              <IoMdSearch size={25} />
            </button>
          </form>
        </div>

        <div className="search">
          <form onSubmit={handleSearch} className="search-bar">
            <FaUserCircle size={25} />
            <input
              type="text"
              placeholder="Search By SPV Name"
              name="spvvName"
              value={searchTerms.spvvName}
              onChange={handleInputChange}
            />
            <button type="submit">
              <IoMdSearch size={25} />
            </button>
          </form>
        </div>

        <div className="search">
          <form onSubmit={handleSearch} className="search-bar">
            <FaUserCircle size={25} />
            <input
              type="text"
              placeholder="Search By Individual Name"
              name="clientName"
              value={searchTerms.clientName}
              onChange={handleInputChange}
            />
            <button type="submit">
              <IoMdSearch size={25} />
            </button>
          </form>
        </div>

        {/* User Card Display Section */}
        {users && users.length > 0 && (
          <div className="usercards">
            {users.map((user) => (
              <div
                key={user._id}
                className="cards"
                onClick={(e) => handleUserClick(user._id, e)}
              >
                <div className="cardetails">
                  <h2>{user.name}</h2>
                  <p>{user.email}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default Profile;
