import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Addguest } from "../../redux/action/companyaction";
import "./Guest.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Guest = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const { companyid } = location.state || {}; // Retrieve companyid from state
  const [guestCount, setGuestCount] = useState(0);
  const [guests, setGuests] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams(); // Get id from the URL params

  const handleGuestCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setGuestCount(count);
    setGuests(new Array(count).fill({ name: "", age: "", file: null }));
  };

  const handleInputChange = (index, e) => {
    const { name, value, files } = e.target;
    const updatedGuests = [...guests];
    updatedGuests[index] = {
      ...updatedGuests[index],
      [name]: files ? files[0] : value,
    };
    setGuests(updatedGuests);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", id); // Add id to FormData
    formData.append("people", guestCount);
    formData.append("Companyid", companyid); // Add companyid to FormData

    guests.forEach((guest, index) => {
      formData.append(`Name`, guest.name);
      formData.append(`Age`, guest.age);
      formData.append(`files`, guest.file);
    });
console.log("form",formData);

    dispatch(Addguest(formData,navigate));
  };

  return (
    <div className="guestall">
      <div className="proples">
        <Form>
          <Form.Group className="mb-3" controlId="guestCount">
            <Form.Label>No. Of Guests</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of guests"
              onChange={handleGuestCountChange}
            />
          </Form.Group>
        </Form>
      </div>

      <div className="guestform">
        <Form onSubmit={handleSubmit}>
          {guests.map((guest, index) => (
            <div key={index} className="guest-entry">
              <Form.Group className="mb-3" controlId={`name-${index}`}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  value={guest.name}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId={`age-${index}`}>
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  placeholder="Enter age"
                  value={guest.age}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId={`file-${index}`}>
                <Form.Label>Upload Document</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Form.Group>
            </div>
          ))}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Guest;
