import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllNotifications,
  getAllNews,
  getmyclient,
} from "../../redux/action/companyaction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; // Make sure to include this if you want to customize styles
import Header from "../Header";
import Footer from "../Footer/Footer";
import Temp from "../Temp";

import "./userdashboard.css";

function UserDashboard() {
  const images = [
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724670951/r9jobm0dsggpfzvuadvx.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671013/th31pqoirscjhv75ybrr.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671014/aciocou0adxwldfikfmz.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671015/mtg5ugizbl0ezxfw32ml.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671016/leowxwczbjeyvhugx2n3.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671017/uzuu3rh9am0huku8z3sy.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671017/aponttzbjjulmft7gmbg.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671017/fxqdffourjjur5v2gbxa.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671017/gpo2srgqxv4q9wuqxaun.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671019/gliduajo70max6oll9bw.jpg",
    "https://res.cloudinary.com/dbibpsvgt/image/upload/v1724671019/eywcadz2nv5kdqmsxpmy.jpg",
    // Add other images
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Hide prev/next buttons
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [news, setNews] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showScrollMessage, setShowScrollMessage] = useState(true);


  useEffect(() => {
    const getmyprofile = async () => {
      const response = await dispatch(getmyclient());
      if (!response) return navigate("/")
      
    }
    getmyprofile()
  },[dispatch,navigate])

  useEffect(() => {
    const fetchData = async () => {
      const notificationsResponse = await dispatch(getAllNotifications());
      setNotifications(notificationsResponse);
      const newsResponse = await dispatch(getAllNews());
      setNews(newsResponse);
    };
    fetchData();

    
    // Automatically hide scroll message after 5 seconds
    const timer = setTimeout(() => {
      setShowScrollMessage(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    // Reset popup state on each login (or each dashboard load after login)
    localStorage.removeItem("popupShown");
  }, []);

 

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleQuestionClick = (index) => {
    navigate("/user/temp");
  };

  // Handle pop-up close

  const handleClosePopup = () => {
    // Close the popup and store the "popupShown" state in localStorage

    localStorage.setItem("popupShown", "true");
  };

  const dropdowns = [
    { question: "When and Where my Training Starts ?", answer: "Coming Soon" },
    {
      question:
        "When Will we get the Mobilisation Advance and EMD Return Date?",
      answer: "Coming Soon",
    },
    {
      question: "When will we start the work and Where?",
      answer: "Coming Soon",
    },
    {
      question: "When will we get Sketches and Drawings?",
      answer: "Coming Soon",
    },
    { question: "When will we get the Scope of Work?", answer: "Coming Soon" },
    {
      question: "Whom should we report to and ask queries if any?",
      answer: "Coming Soon",
    },
    {
      question: "When will I get my Bank Account opened?",
      answer: "Coming Soon",
    },
  ];


  return (
    <div className="fisssss" style={{ backgroundColor: "#051E3F" }}>
      <Header />
      {/* Scroll Down Message */}
      {showScrollMessage && (
        <div className="scroll-down-message">
          <p>Scroll Down</p>
          <div className="arrow-down">↓</div>
        </div>
      )}
      
      <Temp />

      <hr />
      <div className="getting" style={{ margin: "2rem" }}>
        <div className="notification">
          <div className="notificationheading">
            <h4>Updates</h4>
          </div>
          <hr style={{ margin: "0" }} />
          <div className="notification-content">
            {notifications.length > 0 ? (
              notifications.map((item, index) => (
                <div
                  className="notificationdetail"
                  style={{ cursor: "pointer", overflow: "auto" }}
                  key={index}
                >
                  <ul>
                    <li>
                      <a
                        href={item.pdf.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </li>
                  </ul>
                </div>
              ))
            ) : (
              <>
                <p>No Updates found.</p>
                <p>Coming Soon..</p>
              </>
            )}
          </div>
        </div>

        <div
          className="messages"
          style={{ backgroundColor: "rgb(211 213 219)" }}
        >
          <div
            className="notificationheading"
            style={{
              backgroundColor: "rgb(211 213 219)",
              borderRadius: "13px",
            }}
          >
            <h4>FAQ</h4>
          </div>
          <hr style={{ margin: "0px" }} />
          <div className="update-content">
            {dropdowns.map((item, index) => (
              <div
                key={index}
                className={`dropdown-container ${
                  openDropdown === index ? "open" : ""
                }`}
              >
                <div
                  className="dropdown-question"
                  onClick={() => handleQuestionClick(index)} // Handle question click
                >
                  {item.question}
                </div>
                <div className="dropdown-content">{item.answer}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="notification">
          <div className="notificationheading">
            <h4>News</h4>
          </div>
          <hr style={{ margin: "0" }} />
          <div className="notification-content">
            {news.length > 0 ? (
              news.map((item, index) => (
                <div
                  className="notificationdetail"
                  style={{ cursor: "pointer", overflow: "auto" }}
                  key={index}
                >
                  <ul>
                    <li>
                      <a
                        href={item.pdf.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.Headline}
                      </a>
                    </li>
                  </ul>
                </div>
              ))
            ) : (
              <>
                <p>No News found.</p>
                <p>Coming Soon..</p>
              </>
            )}
          </div>
        </div>
      </div>

      <hr />
      <div className="highlight">
        <h3 style={{color:"#fff"}}>Highlights</h3>
        <div className="photogallery">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="photos circular-image">
                <img src={image} alt={`image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <hr style={{ margin: "0" }} />
      <Footer />
    </div>
  );
}

export default UserDashboard;
