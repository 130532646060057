import React, { useEffect, useState } from "react";
import "./NdaDetails.css";
import logo from "../logo-vpvv.png";
import { useDispatch } from "react-redux";
import { singlenda } from "../../redux/action/useravtion";
import { useParams } from "react-router-dom";
import { ViewDocuments } from "../../redux/action/companyaction";
const NdaDetails = () => {
  const [nda, setNda] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchdata = async () => {
      const user = await dispatch(singlenda(id));
      console.log("Usersssss", user);
      setNda(user);
    };
    fetchdata();
  }, [dispatch, id]);
    
  const handletoopenview = async (key) => {
    console.log(key);

    // Replace with logic for generating a pre-signed URL if needed
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
      console.log("key", key);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="allNda">
      <img style={{ width: "10rem" }} src={logo} alt="" />
      <h2 style={{ color: "black" }}>
        VPVV TECHNO CONSTRUCTION PRIVATE LIMITED
      </h2>
      <div className="ndaBox">
        <div className="ndaheading">
                  <h3>{ nda.CompanyName }</h3>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>REPRESENTATIVE NAME</h5>
          <h5>{nda.Representative}</h5>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>EMAIL</h5>
          <h5>{nda.Email}</h5>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>REFERENCE</h5>
          <h5>{nda.Reference}</h5>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>CONTACT NO.</h5>
          <h5>{nda.ContactNo}</h5>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>COUNTRY</h5>
          <h5>{nda.Country}</h5>
        </div>
        <hr />
        <div className="ndaDetails">
          <h5>CITY</h5>
          <h5>{nda.City}</h5>
        </div>
        <hr />
              {
                  nda && nda?.Nda?.map((item) => (
                    <div className="ndaDetails">
                    <h5>NDA DOCUMENT</h5>
                    <button onClick={()=>handletoopenview(item.key)}>Preview</button>
                  </div>
                  ))
       }
      </div>
    </div>
  );
};

export default NdaDetails;
