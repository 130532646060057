import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const AllUser = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const { data } = await axios.get(
          "http://localhost:4000/api/v1/getallClient"
        );
        setGuests(data.client);
        console.log("dataaaaa", data.client);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchGuests();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuests = guests.filter(
    (guest) =>
      guest.name && guest.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredGuests.map((guest) => {
        const directors = guest.SpvDirectors
          ? guest.SpvDirectors.map((director) => director.DirectorName)
          : [];
        
        const filledDirectors = [
          ...directors,
          ...Array(7 - directors.length).fill(""),
        ].slice(0, 7); 
        
        const nominees = guest.Nominee
          ? guest.Nominee.map((nominee) => nominee.NomineeName)
          : [];
        
        const filledNominees = [
          ...nominees,
          ...Array(7 - nominees.length).fill(""),
        ].slice(0, 7); 
  
        const contracts = guest.Contract || [];
        
        const filledContracts = contracts.map((contract, idx) => ({
          [`Type of Contract ${idx + 1}`]: contract.TypeOfContract || '',
          [`Contract Value ${idx + 1}`]: contract.ContractValue || '',
          [`Start Contract Tenure ${idx + 1}`]: contract.StartContractTenure || '',
          [`End Contract Tenure ${idx + 1}`]: contract.EndContractTenure || '',
          [`EMD Amount ${idx + 1}`]: contract.EmdAmount || '',          
        }));
  
        const contractData = filledContracts.reduce((acc, contract) => {
          return { ...acc, ...contract };
        }, {});
  
        return {
          name: guest.name,
          email: guest.email,
          spvvName: guest.spvvName,
          ...filledDirectors.reduce((acc, director, index) => {
            acc[`Director ${index + 1}`] = director;
            return acc;
          }, {}),
          ...filledNominees.reduce((acc, nominee, index) => {
            acc[`Nominee ${index + 1}`] = nominee;
            return acc;
          }, {}),
          ...contractData,
        };
      })
    );
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CompanyList");
    XLSX.writeFile(wb, "CompaniesDataList.xlsx");
  };

  const renderRows = () => {
    return filteredGuests.map((guest, index) => (
      <tr key={`row-${index}`}>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.name}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.email}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.spvvName}
        </td>
        {guest.SpvDirectors &&
          guest.SpvDirectors.map((director, idx) => (
            <td
              key={`director-${idx}`}
              style={{ border: "2px solid #ddd", padding: "8px" }}
            >
              {director.DirectorName}
            </td>
          ))}
        {[...Array(Math.max(0, 5 - (guest.SpvDirectors?.length || 0)))].map(
          (_, idx) => (
            <td
              key={`empty-director-${idx}`}
              style={{ border: "2px solid #ddd", padding: "8px" }}
            ></td>
          )
        )}

        {guest.Nominee &&
          guest.Nominee.map((nominee, idx) => (
            <td
              key={`nominee-${idx}`}
              style={{ border: "2px solid #ddd", padding: "8px" }}
            >
              {nominee.NomineeName}
            </td>
          ))}
        {[...Array(Math.max(0, 5 - (guest.Nominee?.length || 0)))].map(
          (_, idx) => (
            <td
              key={`empty-nominee-${idx}`}
              style={{ border: "2px solid #ddd", padding: "8px" }}
            ></td>
          )
        )}

        {guest.Contract && guest.Contract.length > 0 ? (
          guest.Contract.map((contract, idx) => (
            <React.Fragment key={`contract-${idx}`}>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {contract.TypeOfContract}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {contract.ContractValue}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {contract.EmdAmount}
              </td>
            </React.Fragment>
          ))
        ) : (
          <>
            <td style={{ border: "2px solid #ddd", padding: "8px" }}></td>
            <td style={{ border: "2px solid #ddd", padding: "8px" }}></td>
            <td style={{ border: "2px solid #ddd", padding: "8px" }}></td>
          </>
        )}
      </tr>
    ));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Email</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              SPV Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Director 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Director 2
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Director 3
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Director 4
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Director 5
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 2
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 3
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 4
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 5
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Type of Contract 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Contract Value 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              EMD Amount 1
            </th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default AllUser;
