import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import logo from "../logo-vpvv.png";
import "./Activity.css";

const ActivityLog = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivityLog = async () => {
      try {
        const { data } = await axios.get("http://localhost:4000/api/v1/getallactivity");
        setActivityLog(data.activity);
        console.log("data", data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch activity log");
        setLoading(false);
      }
    };

    fetchActivityLog();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLog = activityLog.filter(
    (entry) =>
      entry.User?.name && // Changed from entry.user?.username to entry.User?.name
      entry.User.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Convert date to IST format for display
  const formatToIST = (dateString) => {
    return new Date(dateString).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredLog.map((entry) => ({
        "User Name": entry.User?.name, // Accessing User name correctly
        "Company Name": entry.Company?.name, // Accessing Company name correctly
        Action: entry.action,
        Description: entry.description,
        Date: formatToIST(entry.createdAt), // Format date to IST for download
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ActivityLog");
    XLSX.writeFile(wb, "ActivityLogData.xlsx");
  };

  const renderRows = () => {
    return filteredLog.map((entry, index) => (
      <tr key={`row-${index}`}>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.User?.name}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.Company?.name}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.action}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.description}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{formatToIST(entry.createdAt)}</td>
      </tr>
    ));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <img style={{ width: "7rem", marginTop: "2rem" }} src={logo} alt="logo" />
      <div className="activityheading">
        <h2>Staff Activity Log</h2>
      </div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by user name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>User Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Company Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Action</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Description</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Date</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default ActivityLog;
