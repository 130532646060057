import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleuser } from "../../redux/action/useravtion";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoAddSharp } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import "./Single.css";
import { Card, ListGroup } from "react-bootstrap";
import image from "../logo-vpvv.png";
import { ViewDocuments } from "../../redux/action/companyaction";

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singleuser(id));
  }, [dispatch, id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handletoprofile = () => {
    navigate("/admin/profile");
  };

  const handletoopenview = async (key) => {
    console.log(key);

    // Replace with logic for generating a pre-signed URL if needed
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
      console.log("key", key);
    } catch (error) {
      console.log(error);
    }
  };

  const handletoaddSpvDirector = (id) => {
    navigate(`/addSpvDirectors/${id}`);
  };

  const handletoaddDirector = (id) => {
    users.individual === "true"
      ? navigate(`/admin/IndividualAddDocument/${id}`)
      : navigate(`/adddirectors/${id}`);
  };
  const handletoaddNominee = (id) => {
    console.log(id);

    users.individual === "true"
      ? navigate(`/IndividualAddNomineeAdd/${id}`)
      : navigate(`/AddNominee/${id}`);
  };
  const handletoaddCompnayDocs = (id) => {
    console.log(id);
    navigate(`/UploadDocuments/${id}`);
  };
  const handletoaddSpvCompnayDocs = (id) => {
    console.log(id);
    navigate(`/UploadSpvDocuments/${id}`);
  };
  const handletoaddContract = (id) => {
    users.individual === "true"
      ? navigate(`/IndividualAddContract/${id}`)
      : navigate(`/AddContract/${id}`);
  };
  const handletoaddSingleDirector = (companyid, id) => {
    navigate(`/directorupdate/${companyid}`, { state: { id } });
    console.log(id);
  };

  const handletoaddSingleNominee = (companyid, id) => {
    navigate(`/nomineeupdate/${companyid}`, { state: { id } });
  };
  const updateSpvDirector = (companyid, id) => {
    navigate(`/spvdirectorupdate/${companyid}`, { state: { id } });
  };

  const handletoaddPayments = (id) => {
    console.log(id);
    navigate(`/AddPayments/${id}`);
  };
  const handletoaddWorkOreder = (id) => {
    console.log(id);
    navigate(`/addOrder/${id}`);
  };
  const handletoeditDocument = () => {
    navigate(`/editCompanyDoc/${id}`);
  };
  //individualss
  const handletoeditIndividual = (id) => {
    navigate(`/editIndividual/${id}`);
  };
  const handletoaddEmdReturned = (id) => {
    navigate(`/admin/EmdReturned/${id}`)
  }
  return (
    <div className="total">
      <div className="some">
        <button className="to" onClick={handletoprofile}>
          <IoMdArrowBack size={27} />
        </button>
        <div className="company">
          {users.individual === "true" ? (
            <div>
              {" "}
              <h4>{users.name}</h4> <p>{users.email}</p>
            </div>
          ) : (
            users.companylogo && (
              <>
                <img src={users.companylogo.url} alt="logo" />
                <h4>{users.name}</h4>
                <p>{users.email}</p>
              </>
            )
          )}
        </div>

        <div className="up">
          {users.individual === "true" ? (
            <h3>Individual</h3>
          ) : (
            <h3>Directors</h3>
          )}
          {users.individual === "true" ? (
            <div className="directors">
              {users?.IndividualDocs?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "16rem",
                    background: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <IoIosAddCircleOutline
                    size={40}
                    style={{ cursor: "pointer" }}
                    onClick={() => handletoeditIndividual(users._id, item._id)}
                  />
                  {item.photo?.key ? (
                    <button
                      className="new"
                      onClick={() => handletoopenview(item.photo?.key || image)}
                      style={{
                        margin: "10px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      View Image
                    </button>
                  ) : (
                    <span>Not available</span>
                  )}
                  <Card.Body>
                    <Card.Title>{users.name || "N/A"}</Card.Title>
                    <Card.Text>{users.email || "N/A"}</Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      AAdharCard
                      {item.AAdhar?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.AAdhar.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      PanCard
                      {item.Pan?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.Pan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Passport
                      {item.Passport?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.Passport.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      VoterID
                      {item.VoterId?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.VoterId?.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}

              <button
                className="adds"
                onClick={() => handletoaddDirector(users._id)}
              >
                <IoAddSharp size={30} />
              </button>
            </div>
          ) : (
            <div className="directors">
              {users?.Directors?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "16rem",
                    background: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <IoIosAddCircleOutline
                    size={40}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handletoaddSingleDirector(users._id, item._id)
                    }
                  />
                  {item.Directorphoto?.key ? (
                    <button
                      className="new"
                      onClick={() =>
                        handletoopenview(item.Directorphoto?.key || image)
                      }
                      style={{
                        margin: "10px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      View Image
                    </button>
                  ) : (
                    <span>Not available</span>
                  )}
                  <Card.Body>
                    <Card.Title>{item.DirectorName || "N/A"}</Card.Title>
                    <Card.Text>{item.Directoremail || "N/A"}</Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      AAdharCard
                      {item.DirectorAadhar?.key ? (
                        <button
                          className="preview"
                          onClick={() =>
                            handletoopenview(item.DirectorAadhar.key)
                          }
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      PanCard
                      {item.DirectorPan?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.DirectorPan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Passport
                      {item.DirectorPassport?.key ? (
                        <button
                          className="preview"
                          onClick={() =>
                            handletoopenview(item.DirectorPassport.key)
                          }
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}

              <button
                className="adds"
                onClick={() => handletoaddDirector(users._id)}
              >
                <IoAddSharp size={30} />
              </button>
            </div>
          )}
        </div>
        <hr />

        {users.individual === "true" ? (
          <div></div>
        ) : (
          <div className="mid">
            <h3>{`${users.name} Documents:`}</h3>
            <button className="adds">
              <IoAddSharp
                size={30}
                onClick={() => handletoaddCompnayDocs(users._id)}
              />
            </button>
            <div className="docs">
              {users?.companydocs?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "25rem",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                  }}
                >
                  <button
                    style={{ borderRadius: "12px" }}
                    onClick={() => handletoeditDocument()}
                  >
                    Edit
                  </button>

                  <ListGroup variant="flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Incorporation Certificate{" "}
                      {item.Certificate && (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.Certificate.key)}
                        >
                          Preview
                        </button>
                      )}
                    </ListGroup.Item>{" "}
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Company Pan
                      {item.CompanyPan && (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyPan.key)}
                        >
                          Preview
                        </button>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Company Tan
                      {item.CompanyTan && (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyTan.key)}
                        >
                          Preview
                        </button>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      MOU
                      {item.MOA && (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.MOA.key)}
                        >
                          Preview
                        </button>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}
            </div>
          </div>
        )}

        {users.individual === "true" ? (
          <div></div>
        ) : (
          <div className="mid">
            <h3>{`${users.spvvName} Documents:`} </h3>
            <button className="adds">
              <IoAddSharp
                size={30}
                onClick={() => handletoaddSpvCompnayDocs(users._id)}
              />
            </button>
            <div className="docs">
              {users?.spvvdocs?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "25rem",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                  }}
                >
                  <button
                    style={{ borderRadius: "12px" }}
                    onClick={() => handletoeditDocument()}
                  >
                    Edit
                  </button>
                  <ListGroup variant="flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Incorporation Certificate{" "}
                      {item.Certificate && item.Certificate.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.Certificate.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <p>Not Available</p>
                      )}
                    </ListGroup.Item>{" "}
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Company Pan
                      {item.CompanyPan && item.CompanyPan.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyPan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <p>Not Available</p>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Company Tan
                      {item.CompanyTan && item.CompanyTan.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyTan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <p>Not Available</p>
                      )}
                    </ListGroup.Item>
                    {users?.Contract.map((data) => (
                      <ListGroup.Item
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        MOU
                        {data.Mou && (
                          <button
                            className="preview"
                            onClick={() => handletoopenview(data.Mou.key)}
                          >
                            Preview
                          </button>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              ))}
            </div>
          </div>
        )}

        <hr />
        {users.individual === "true" ? (
          <div></div>
        ) : (
          <div className="up">
            <h3>SPV Directors</h3>
            <div className="directors">
              {users?.SpvDirectors?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "16rem",
                    background: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <IoIosAddCircleOutline
                    size={40}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateSpvDirector(users._id, item._id)}
                  />
                  {item.Directorphoto?.key ? (
                    <button
                      className="new"
                      onClick={() =>
                        handletoopenview(item.Directorphoto?.key || image)
                      }
                      style={{
                        margin: "10px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      View Image
                    </button>
                  ) : (
                    <span>Not available</span>
                  )}

                  <Card.Body>
                    <Card.Title>{item.DirectorName || "N/A"}</Card.Title>
                    <Card.Text>{item.Directoremail || "N/A"}</Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      AAdharCard
                      {item.DirectorAadhar?.key ? (
                        <button
                          className="preview"
                          onClick={() =>
                            handletoopenview(item.DirectorAadhar.key)
                          }
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      PanCard
                      {item.DirectorPan?.key ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.DirectorPan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Passport
                      {item.DirectorPassport?.key ? (
                        <button
                          className="preview"
                          onClick={() =>
                            handletoopenview(item.DirectorPassport.key)
                          }
                        >
                          Preview
                        </button>
                      ) : (
                        <span>Not available</span>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}

              <button
                className="adds"
                onClick={() => handletoaddSpvDirector(users._id)}
              >
                <IoAddSharp size={30} />
              </button>
            </div>
          </div>
        )}
        <hr />
        <div className="up">
          <h3>Nominees</h3>
          <div className="directors">
            {users?.Nominee?.map((items) => (
              <Card
                key={items._id}
                style={{
                  width: "16rem",
                  background: "rgba(255, 255, 255, 0.6)",
                }}
              >
                <IoIosAddCircleOutline
                  size={40}
                  onClick={() => handletoaddSingleNominee(users._id, items._id)}
                />
                <button
                  className="new"
                  onClick={() => handletoopenview(items.Nomineephoto?.key)}
                  style={{ margin: "10px", padding: "10px", cursor: "pointer" }}
                >
                  View Image
                </button>
                <Card.Body>
                  <Card.Title>{items.NomineeName || "N/A"}</Card.Title>
                  <Card.Text>{items.NomineeEmail || "N/A"}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    AAdharCard
                    {items.NomineeAAdhar?.url ? (
                      <button
                        className="preview"
                        onClick={() =>
                          handletoopenview(items.NomineeAAdhar.key)
                        }
                      >
                        Preview
                      </button>
                    ) : (
                      <span>Not available</span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    PanCard
                    {items.NomineePan?.url ? (
                      <button
                        className="preview"
                        onClick={() => handletoopenview(items.NomineePan.key)}
                      >
                        Preview
                      </button>
                    ) : (
                      <span>Not available</span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Passport
                    {items.NomineePassport?.url ? (
                      <button
                        className="preview"
                        onClick={() =>
                          handletoopenview(items.NomineePassport.key)
                        }
                      >
                        Preview
                      </button>
                    ) : (
                      <span>Not available</span>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            ))}

            <button className="adds">
              <IoAddSharp
                size={30}
                onClick={() => handletoaddNominee(users._id)}
              />
            </button>
          </div>
        </div>
        <hr />

        <div className="bottom">
          <h3>Contract details</h3>
          <button className="adds">
            <IoAddSharp
              size={30}
              onClick={() => handletoaddContract(users._id)}
            />
          </button>
          {users?.Contract?.map((item) => (
            <div className="contract" key={item._id}>
              <div className="main-type">
                <div className="type">
                  <h4>Type Of Contract</h4>
                  <div className="details">{item.TypeOfContract}</div>
                </div>
                <div className="type">
                  <h4>Contract Tenure StartDate</h4>
                  <div className="details">{item.StartContractTenure}</div>
                </div>
                <div className="type">
                  <h4>Contract Tenure EndDate</h4>
                  <div className="details">{item.EndContractTenure}</div>
                </div>
              </div>
              <div className="main-type">
                <div className="type">
                  <h4>Contract Value(cr)</h4>
                  <div className="details">{item.ContractValue}</div>
                </div>
                <div className="type">
                  <h4>End Amount</h4>
                  <div className="details">{item.EmdAmount}</div>
                </div>
              </div>
              <div className="main-type">
                <div className="type">
                  <h4>Total Received</h4>
                  <div className="details">{}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div className="payments">
          <h3>Emd Tranches</h3>
          <button className="adds">
            <IoAddSharp
              size={30}
              onClick={() => handletoaddPayments(users._id)}
            />
          </button>
          {users?.Payments?.map((item) => (
            <div className="each" key={item._id}>
              <div className="main-type">
                <div className="type">
                  <h5>Payment Amount :</h5>
                  <div className="details">{item.payment}</div>
                </div>
                <div className="type">
                  <h5>Date:</h5>
                  <div className="details">{item.createdAt.split("T")[0]}</div>
                </div>
              </div>
              <div className="main-type">
                <div className="type space">
                  <h5>Received :</h5>
                  <div className="details">{item.received}</div>
                </div>
                <div className="type btttts">
                  <h5>Receipt :</h5>
                  <div className="details">
                    <button
                      onClick={() => handletoopenview(item.slip.key)}
                      style={{ height: "2rem", borderRadius: "12px" }}
                    >
                      Preview
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div className="payments">
          <h3>Emd Returned</h3>
          <button className="adds">
            <IoAddSharp
              size={30}
              onClick={() => handletoaddEmdReturned(users._id)}
            />
          </button>
          {users?.Payments?.map((item) => (
            <div className="each" key={item._id}>
              <div className="main-type">
                <div className="type">
                  <h5>Payment Amount :</h5>
                  <div className="details">{item.payment}</div>
                </div>
                <div className="type">
                  <h5>Date:</h5>
                  <div className="details">{item.createdAt.split("T")[0]}</div>
                </div>
                <div className="type">
                  <h5>Reference</h5>
                  <div className="details">
                    21654324841325
                  </div>
                </div>
              </div>
              <div className="main-type">
                <div className="type space">
                  <h5>Mode Of Payment :</h5>
                  <div className="details">{item.received}</div>
                </div>

                <hr />
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div className="workorder">
          <h4>Work Order</h4>
          <button className="adds">
            <IoAddSharp
              size={30}
              onClick={() => handletoaddWorkOreder(users._id)}
            />
          </button>
          <div className="grouuup">
            <div className="order">
              <h5>Work Order :</h5>
              <div className="details">{users.workorder}</div>
            </div>
            <div className="order">
              <h5>Guarantee Bond Issue :</h5>
              <div className="details">{users.guaranteeBond}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
