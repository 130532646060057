import React from "react";
import "./HelpDetailsModal.css"; // Create this CSS file for styling

const HelpDetailsModal = ({ show, onClose, help }) => {
  if (!show) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">
        <h2>Help Details</h2>
        {help && (
          <div className="modal-content">
            <p><strong>Name:</strong> {help.Name}</p>
            <p><strong>Email:</strong> {help.Email}</p>
            <p><strong>Contact:</strong> {help.Contact}</p>
            <p><strong>Query:</strong> {help.Query}</p>
          </div>
        )}
        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default HelpDetailsModal;
