import React, { useState } from "react";
import "../Authentication/Login.css";
import logo from "../logo-vpvv.png";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { Individualogin } from "../../redux/action/companyaction";
import { useNavigate } from "react-router-dom";
import { RiCustomerService2Fill } from "react-icons/ri";
import Footer from "../Footer/Footer";

const Forgetpassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State for loading

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    await dispatch(Individualogin(email, password, navigate));
    setLoading(false); // Stop loading
  };

  const sendtohelpHandler = () => {
    navigate("/general/helpdesk");
  };
  const handletologin = () => {
    navigate("/");
  };

  return (
    <>
      <div className="full">
        <img src={logo} alt="logo" />
        <div className="box">
          <Row className="justify-content-center">
            <Col xs={10} md={6} className="containeraa">
              <h1 className="text-center mt-3">Forget Password</h1>
              <Form onSubmit={submitHandler}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    placeholder="Enter Your Offical Email Given By Vpvv"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                              </Form.Group>
                <Button className="mt-4" type="submit" disabled={loading}>
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Send Otp to Email"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <hr style={{ backgroundColor: "052040", margin: "0rem" }} />
      <Footer />
    </>
  );
};

export default Forgetpassword;
