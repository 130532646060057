import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row,Spinner } from "react-bootstrap";
import logo from "../logo-vpvv.png";
import "./Bankform.css"; // Ensure this file is set up for custom styles if needed
import Header from "../Header";
import { useDispatch } from "react-redux";
import { creatIndividualBAnkaccount } from "../../redux/action/companyaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Individualbank = () => {
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState(false);
  const [numSignatories, setNumSignatories] = useState(1);
  const [numNominees, setNumNominees] = useState(1);

  const countries = [
    "---Select---",
    "Afghanistan",
    "Algeria",
    "Andorra",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bangladesh",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chile",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const countryCodes = [
    "---select---",
    "+1",
    "+20",
    "+27",
    "+30",
    "+31",
    "+32",
    "+33",
    "+34",
    "+36",
    "+39",
    "+41",
    "+43",
    "+44",
    "+45",
    "+46",
    "+47",
    "+48",
    "+49",
    "+50",
    "+51",
    "+52",
    "+53",
    "+54",
    "+55",
    "+56",
    "+57",
    "+60",
    "+61",
    "+62",
    "+63",
    "+64",
    "+65",
    "+66",
    "+70",
    "+71",
    "+72",
    "+73",
    "+74",
    "+75",
    "+76",
    "+77",
    "+78",
    "+79",
    "+80",
    "+81",
    "+82",
    "+84",
    "+85",
    "+87",
    "+88",
    "+89",
    "+90",
    "+91",
    "+94",
    "+95",
    "+960",
    "+961",
    "+962", // Lebanon, Jordan
    "+965",
    "+966",
    "+967",
    "+968", // Kuwait, Saudi Arabia, Yemen, Oman
    "+970",
    "+971",
    "+972",
    "+973",
    "+974",
    "+975",
    "+976",
    "+977", // Palestine, UAE, Israel, Bahrain, Qatar, Bhutan, Mongolia, Nepal
    "+993",
    "+994",
    "+995",
    "+996",
    "+997",
    "+998",
    "+999",
    "+237",
    "+241",
    "+240",
    "+290",
    "+235",
    "+227",
    "+223",
    "+229",
    "+228",
  ];
  const handlePoliticallyExposedChange = (e) => {
    setIsPoliticallyExposed(e.target.value === "Yes");
  };

  
  const location = useLocation();

  // Handle changes in nominee details
  const handleNomineeChange = (index, field, value) => {
    const updatedNominees = [...nominees];
    updatedNominees[index] = { ...updatedNominees[index], [field]: value };
    setNominees(updatedNominees);
  };

  const [date, setDate] = useState("");
  const [nameOfIndividual, setNameOfIndividual] = useState("");
  const [title, setTitle] = useState("");
  const [industryCurrentlyemployed, setIndustryCurrentlyemployed] =
    useState("");
  const [dateOfbirth, setDateOfBirth] = useState("");
  const [countryofbirth, setCountryofBirth] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [voteridno, setVoterIDNo] = useState("");
  const [pannumber, setPanNumber] = useState("");
  const [permanentaddress, setPermanentAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingZipCode, setMailingZipCode] = useState("");
  const [mailingCountry, setMailingCountry] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [politicallyExposed, setPoliticallyExposed] = useState("");
  const [accountType, setAccountType] = useState("");
  const [currency, setCurrency] = useState("");
  const [internetBanking, setInternetBanking] = useState(false);
  const [debitCard, setDebitCard] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [position, setPosition] = useState("");
  const [affidavit, setAffidavit] = useState(null);

  const [nominees, setNominees] = useState(
    Array(2).fill({
      fullName: "",
      email: "",
      contactNumber: "",
      passport: "",
      pan: "",
    })
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  // State for file uploads
  const [image, setImage] = useState(null);
  const [passport, setPassport] = useState(null);
  const [voter, setVoter] = useState(null);
  const [pan, setPan] = useState(null);
  const [nominee1Image, setNominee1Image] = useState(null);
  const [nominee1VoterIdProof, setNominee1VoterIdProof] = useState(null);
  const [nominee1Passport, setNominee1Passport] = useState(null);
  const [nominee1PAN, setnominee1PAN] = useState(null);
  const [nominee2Image, setNominee2Image] = useState(null);
  const [nominee2VoterIdProof, setNominee2VoterIdProof] = useState("");
  const [nominee2Passport, setNominee2Passport] = useState("");
  const [nominee2PAN, setNominee2PAN] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state
  const submithandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append basic details
    formData.append("Date", date);
    formData.append("NameOfIndividual", nameOfIndividual);
    formData.append("Title", title);
    formData.append("IndustryCurrentlyemployed", industryCurrentlyemployed);
    formData.append("DateOfBirtht", dateOfbirth);
    formData.append("CountryofBirth", countryofbirth);
    formData.append("PassportNo", passportNo);
    formData.append("VoterIDNo", voteridno);
    formData.append("PanNumber", pannumber);

    // Append registered office address details
    formData.append("PermanentAddress", permanentaddress);
    formData.append("City", city);
    formData.append("ZipCode", zipCode);
    formData.append("Country", country);

    // Append mailing address details
    formData.append("MailingAddress", mailingAddress);
    formData.append("MailingCity", mailingCity);
    formData.append("MailingZipCode", mailingZipCode);
    formData.append("MailingCountry", mailingCountry);

    // Append contact information
    formData.append("OfficalEmail", officialEmail);
    formData.append("ContactNumber", contactNumber);
    formData.append("PositionPolitical", politicallyExposed);

    // Append account preferences
    formData.append("AccountType", accountType);
    formData.append("Currency", currency);
    formData.append("TermandCondition", termsAccepted);
    formData.append("InternetBanking", internetBanking);
    formData.append("DebitCard", debitCard);

    // Append authorized signatories (assuming you have validation in place)
 

    // Append nominees (assuming you have validation in place)
    for (let i = 0; i < nominees.length; i++) {
      formData.append("NomineeFullNAme[]", nominees[i]?.fullName);
      formData.append("Nomineeemail[]", nominees[i]?.email);
      formData.append("NomineeContactNumber[]", nominees[i].contactNumber);
      formData.append("NomineePassportNo[]", nominees[i].passport);
      formData.append("NomineePanNo[]", nominees[i].pan);
    }

    // Append file uploads
    formData.append("Image", image);
    formData.append("Passport", passport);
    formData.append("Voter", voter);
    formData.append("Pan", pan);
    formData.append("Nominee1Image", nominee1Image);
    formData.append("Nominee1VoterIdProof", nominee1VoterIdProof);
    formData.append("Nominee1Passport", nominee1Passport);
    formData.append("Nominee1PAN", nominee1PAN);
    formData.append("Nominee2Image", nominee2Image);
    formData.append("Nominee2VoterIdProof", nominee2VoterIdProof);
    formData.append("Nominee2Passport", nominee2Passport);
    formData.append("Nominee2PAN", nominee2PAN);
    setLoading(true); // Set loading to true when form submission starts

    dispatch(creatIndividualBAnkaccount(formData, id, navigate)).then(() => {
      setLoading(false); // Set loading to false after submission
      navigate("/userDashboard");
    })
    .catch((error) => {
      setLoading(false); // Set loading to false if submission fails
      console.error("Failed to add payment:", error);
      alert("Failed to add BankAccount Inormation. Please try again.");
    });;
  };
  const { Bankname } = location.state || {};
  const [bankLogo, setBankLogo] = useState(null);

  // Object mapping bank names to their respective logo URLs
  const bankLogos = {
    TD: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/t4bu4wzje3spb6cmrkb1.png",
    WELLSFARGO:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725534001/Wells_Fargo_Logo__2020_.svg_umkjpq.png",
    JP: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/napjigpkysj6wp8xrmlv.png",
    BOFA: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531681/mera%20bank/wuysfcffyefsu8eqoah7.webp",
    GOLDMAN:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/slftnh50pqkmm20facpq.png",
    HSBC: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/c7pctkstfqwn0gcsh3zt.png",
    DEUTSCHE:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531698/mera%20bank/tmwykbmwcfm4uqm9psic.png",
  };
  useEffect(() => {
    if (Bankname && bankLogos[Bankname]) {
      setBankLogo(bankLogos[Bankname]);
    } else {
      setBankLogo(null); // Optionally handle when the bank logo is not found
    }
  }, [Bankname]);
  return (
    <div className="banking">
      <div className="headerr">
        <img src={logo} alt="VPVV" />
        <h3>VPVV TECHNO CONSTRUCTION PRIVATE LIMITED</h3>
        <h4 style={{ color: "#052040" }}>
          Global Corporate Bank Account Opening Form
        </h4>
      </div>
      <div className="container" style={{height:"100%"}}>
        <div className="bankalls">
          {bankLogo ? (
            <img
              style={{ width: "11rem", borderRadius: "12px", margin: "1rem" }}
              src={bankLogo}
              alt={`${Bankname} Logo`}
            />
          ) : (
            "No bank logo available"
          )}

          <Form onSubmit={submithandler}>
            {/* Company Information */}
            <h5>Individual Information</h5>
            <h6>Basic Details</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Date(Form Submission Date)</Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Name of Individual</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    value={nameOfIndividual}
                    onChange={(e) => setNameOfIndividual(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label> Title/Position</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title / Position"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Individual Detials</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Industry Currently employed</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g., Manufacturing, IT, etc."
                    value={industryCurrentlyemployed}
                    onChange={(e) =>
                      setIndustryCurrentlyemployed(e.target.value)
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={dateOfbirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country of Birth</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter country of incorporation"
                    value={countryofbirth}
                    onChange={(e) => setCountryofBirth(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Addititonal Details</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Passport No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Passport No."
                    value={passportNo}
                    onChange={(e) => setPassportNo(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Voter ID No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Voter ID No."
                    value={voteridno}
                    onChange={(e) => setVoterIDNo(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter PAN"
                    value={pannumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Address Information</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Permanent Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter the complete Address"
                    value={permanentaddress}
                    onChange={(e) => setPermanentAddress(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>ZIP/Postal Code</Form.Label>
                  <Form.Control
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    {countries.map((country) => (
                      <option key={country}>{country}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Mailing Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter the complete Mailing address"
                    value={mailingAddress}
                    onChange={(e) => setMailingAddress(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      placeholder="Enter City"
                      value={mailingCity}
                      onChange={(e) => setMailingCity(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>ZIP/Postal Code</Form.Label>
                  <Form.Control
                    placeholder="Enter the Zip Code"
                    value={mailingZipCode}
                    onChange={(e) => setMailingZipCode(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={mailingCountry}
                    onChange={(e) => setMailingCountry(e.target.value)}
                    required
                  >
                    {countries.map((country) => (
                      <option key={country}>{country}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <h6>Contact Information</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Official Communication Email(Given By VPVV)
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter official email"
                    value={officialEmail}
                    onChange={(e) => setOfficialEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Row>
                    <Col xs={4}>
                      <Form.Control as="select">
                        {countryCodes.map((code) => (
                          <option key={code}>{code}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact number"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <h6>Politically Exposed Person Declaration</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Is the person politically exposed?</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handlePoliticallyExposedChange}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {isPoliticallyExposed && (
                <Col>
                  <Form.Group>
                    <Form.Label>If Yes, Please Specify</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Does the signatory or any associate hold a position within a government institution/parastatal/entity?"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Enter their position"
                    />
                    <Form.Label>Attach Affidavit</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setAffidavit(e.target.files[0])}
                    />
                    <Form.Text className="text-muted">
                      By attaching this affidavit, you confirm that you
                      willingly allow us to block the account if the government
                      deems it necessary, and this account will not have any
                      transactions relating to the government.
                    </Form.Text>
                  </Form.Group>
                </Col>
              )}
            </Row>

            {/* Authorized Signatory Information */}
            {/* Authorized Signatory Information */}

            {/* Nominee Information */}
            <div>
              {[...Array(2)].map((_, index) => (
                <div key={index}>
                  <h6>Nominee {index + 1}</h6>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter full name"
                          value={nominees[index].fullName}
                          onChange={(e) =>
                            handleNomineeChange(
                              index,
                              "fullName",
                              e.target.value
                            )
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email address"
                          value={nominees[index].email}
                          onChange={(e) =>
                            handleNomineeChange(index, "email", e.target.value)
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>
                        <Row>
                          <Col xs={4}>
                            <Form.Control as="select">
                              {countryCodes.map((code) => (
                                <option key={code}>{code}</option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col xs={8}>
                            <Form.Control
                              type="text"
                              placeholder="Enter contact number"
                              value={nominees[index].contactNumber}
                              onChange={(e) =>
                                handleNomineeChange(
                                  index,
                                  "contactNumber",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Passport No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Passport No."
                            value={nominees[index].passport}
                            onChange={(e) =>
                              handleNomineeChange(
                                index,
                                "passport",
                                e.target.value
                              )
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>PAN</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="PAN"
                            value={nominees[index].pan}
                            onChange={(e) =>
                              handleNomineeChange(index, "pan", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                </div>
              ))}
            </div>
            {/* Account Preferences */}
            <h5>Account Preferences</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Account Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option>Individual Account</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    as="select"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option>USD</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Initial Deposit Amount</Form.Label>
                  <Form.Control type="text" value="$100,000.00" readOnly />
                </Form.Group>
              </Col>
            </Row>

            {/* Services */}
            <h5>Services</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Internet Banking</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Request Internet Banking"
                    checked={internetBanking}
                    onChange={(e) => setInternetBanking(e.target.checked)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Debit Card</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Request Debit Card"
                    checked={debitCard}
                    onChange={(e) => setDebitCard(e.target.checked)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Document Uploads */}
            {/* Document Uploads */}
            <h5>Document Uploads</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label> Image</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setImage(e.target.files[0])
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Passport</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setPassport(e.target.files[0])
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Voter Id.</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setVoter(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pan</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPan(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 1 Image</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee1Image(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 1 Voter Id Proof</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee1VoterIdProof(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 1 Passport</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setNominee1Passport(e.target.files[0])
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 1 PAN</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setnominee1PAN(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 2 Image</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee2Image(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 2 Voter Id Proof</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee2VoterIdProof(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 2 Passport</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee2Passport(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nominee 2 PAN</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setNominee2PAN(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Terms & Conditions */}
            <h5>Declaration</h5>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="I/We hereby certify that all details entered in this form are accurate and complete to the best of my knowledge."
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                required
              />
            </Form.Group>

            {/* Submit Button */}
            
            <Button variant="primary" type="submit" className="form-submit-btn" 
              disabled={loading}
            
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Finish"}{" "}
              </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Individualbank;
