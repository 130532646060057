import React, { useEffect, useState } from "react";
import "./Charity.css";
import logo from "../logo-vpvv.png";
import { useDispatch } from "react-redux";
import { getallCharity } from "../../redux/action/companyaction";
import { FaStar } from "react-icons/fa6";
import { Carousel } from "react-bootstrap";

const Charity = () => {
  const [charity, setCharity] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await dispatch(getallCharity()); // Call the action creator function
        setCharity(data.charity);
      } catch (error) {
        console.error("Error fetching charity data", error);
      }
    };

    fetchdata();
  }, [dispatch]);
  return (
    <>
      <div className="charityall">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://vpvvpublic.s3.ap-south-1.amazonaws.com/charity-images/WhatsApp+Image+2024-10-04+at+11.45.01+AM.jpeg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://vpvvpublic.s3.ap-south-1.amazonaws.com/charity-images/charityimage.jpeg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://vpvvpublic.s3.ap-south-1.amazonaws.com/charity-images/WhatsApp+Image+2024-10-04+at+11.44.55+AM.jpeg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>

        <div className="logo">
          {" "}
          <img style={{ width: "13rem" }} src={logo} alt="" />
          <h2 style={{ color: "black", marginTop: "2rem" }}>
            <strong> GIVING BACK TO THE UNIVERSE </strong>{" "}
          </h2>
        </div>
        <hr style={{}} />
        {charity?.map((item) => (
          <div className="charityImagesWrapper">
            <div className="cahrities">
              <div className="charityImages">
                {/* Repeat charity boxes */}
                {item?.Images?.map((i) => (
                  <div className="charitybox">
                    {" "}
                    <img src={i.image.url} alt="" />{" "}
                  </div>
                ))} 
              </div>
            </div>
          </div>
        ))}

        <hr style={{ color: "white" }} />
        <div className="down">
          <FaStar size={25} />
          <h2>BY THE GOD'S GRACE</h2>
          <FaStar size={25} />
        </div>
      </div>
    </>
  );
};

export default Charity;
