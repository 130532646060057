import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./news.css";
import { IoEyeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import NewsModal from "./NewsModal";
import { createNews, deleteNews, getAllNews } from "../../redux/action/companyaction";
import { useDispatch } from "react-redux";

const News = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [news, setNews] = useState([]);
  const [deletingId, setDeletingId] = useState(null);

  const dispatch = useDispatch();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async (formData) => {
    setLoading(true);
    try {
      await dispatch(createNews(formData));
      console.log("News added:", formData);
    } catch (error) {
      console.error("Error adding news:", error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }

    const response = await dispatch(getAllNews());
    if (response && Array.isArray(response)) {
      setNews(response);
    } else {
      console.error("Failed to fetch news, received:", response);
      setNews([]);
    }
  };

  const handletoOpenPdf = (url) => {
    window.open(url, "_blank");
  };

  const deleteHandler = async (id) => {
    setDeletingId(id);

    await dispatch(deleteNews(id));

    const response = await dispatch(getAllNews());
    if (response && Array.isArray(response)) {
      setNews(response);
    } else {
      console.error("Failed to fetch news, received:", response);
      setNews([]);
    }

    setDeletingId(null);
  };

  useEffect(() => {
    const fetchNews = async () => {
      const response = await dispatch(getAllNews());
      setNews(response);
    };

    fetchNews();
  }, [dispatch]);

  return (
    <div className="allEvent">
      <Sidebar />
      <div className="events">
        <div className="eventbox">
          {news && news.length > 0 ? (
            news.map((item, index) => (
              <div className="eventCards" key={index}>
                {/* <div className="eventbanner">
                  <img src={item.pdf.url} alt="News" />
                </div> */}
                <div className="eventdetails">
                  <p>News Date: {item.Date.split("T")[0]}</p>
                  <p>News Headline: {item.Headline}</p>
                </div>
                <div className="eventlimit">
                  <button onClick={() => handletoOpenPdf(item.pdf.url)}>
                    <IoEyeSharp />
                    View
                  </button>
                  <button
                    style={{ width: "2rem" }}
                    onClick={() => deleteHandler(item._id)}
                    disabled={deletingId === item._id}
                  >
                    {deletingId === item._id ? (
                      <div className="spinner"></div>
                    ) : (
                      <MdDelete size={20} />
                    )}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No news found.</p>
          )}
        </div>
        <div className="createeventbutton">
          <button onClick={handleOpenModal}>Add News</button>
        </div>
      </div>

      <NewsModal
        show={showModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default News;
