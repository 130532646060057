import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import logo from "../logo-vpvv.png";
import "../UserDirector/Direcotr.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, ListGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ViewDocuments } from "../../redux/action/companyaction";
import Footer from "../Footer/Footer";
import Disclaimer from "../Disclaimer/Disclaimer";

const SpvDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { spvDocs } = location.state || [];
  console.log("Docssssssss", spvDocs);

  const handletoprofile = () => {
    navigate("/userDashboard");
  };

  const handletoopenview = async (key) => {
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
      console.log("key", key);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="frontdirector">
        <button className="to" onClick={handletoprofile}>
          <IoMdArrowBack size={27} />
        </button>
        <div className="userdirector">
          <img style={{width:"13rem"}} src={logo} alt="" />
          <h3 className="director-text" style={{ color: "white" }}>
            SPV Documents:
          </h3>
          <div className="mid">
            <div className="docs">
              {spvDocs?.map((item) => (
                <Card
                  key={item._id}
                  style={{
                    width: "25rem",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    marginBottom: "20px",
                  }}
                >
                  <ListGroup variant="flush">
                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2rem",
                      }}
                    >
                      Incorporation Certificate
                      {item.Certificate?.url ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.Certificate.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span style={{ marginRight: "7px" }}>
                          Not available
                        </span>
                      )}
                    </ListGroup.Item>

                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2rem",
                      }}
                    >
                      Company PAN
                      {item.CompanyPan?.url ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyPan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span style={{ marginRight: "7px" }}>
                          Not available
                        </span>
                      )}
                    </ListGroup.Item>

                    <ListGroup.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2rem",
                      }}
                    >
                      Company TAN
                      {item.CompanyTan?.url ? (
                        <button
                          className="preview"
                          onClick={() => handletoopenview(item.CompanyTan.key)}
                        >
                          Preview
                        </button>
                      ) : (
                        <span style={{ marginRight: "7px" }}>
                          Not available
                        </span>
                      )}
                    </ListGroup.Item>

                    
                  </ListGroup>
                </Card>
              ))}
            </div>
          </div>
        </div>
        <Disclaimer/>

      </div>
      <Footer/>
    </>
  );
};

export default SpvDocuments;
