import React from 'react';
import './EventDetailsModal.css'; // Create this CSS file to style the modal

const EventDetailsModal = ({ show, onClose, event }) => {
  if (!show) return null;
console.log("event",event);

  return (
    <div className="eventDetailsModal">
      <div className="modalContent">
        <button className="closeButton" onClick={onClose}>X</button>
        <div className="modalHeader">
          <h2>{event.title}</h2>
          <p>{event.date.split("T")[0]}</p>
        </div>
        <div className="modalBody">
          <img src={event?.banner?.url} alt="Event Banner" />
          <p>{event.description}</p> {/* Add description or any additional details you have */}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsModal;
