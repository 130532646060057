import React, { useState, useEffect } from "react";
import "./Modal.css"; // Reuse the existing CSS or create new styles as needed.
import { ImCross } from "react-icons/im";

const UpdateEventModal = ({ show, onClose, onSubmit, event, loading }) => {
  const [formData, setFormData] = useState({
    date: "",
    title: "",
  });

  useEffect(() => {
    if (event) {
      setFormData({
        date: event.date || "",
        title: event.title || "",
      });
    }
  }, [event]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ date: formData.date, title: formData.title });
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <ImCross className="close-button" onClick={onClose} />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="date">Date:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className="submit-button"
            style={{ backgroundColor: "#007bff" }}
            disabled={loading}
          >
            {loading ? <span className="spinner"></span> : "Update Event"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateEventModal;
