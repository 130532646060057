import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const Bankforms = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const { data } = await axios.get(
          "http://localhost:4000/api/v1/allbank"
        );
        console.log(data);

        setBanks(data.bank);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchBanks();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredBanks = banks.filter(
    (bank) =>
      bank.Company?.name &&
      bank.Company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredBanks.map((bank) => ({
        "Company Name": bank.Company?.name,
        "Spv Name": bank.NameOfOrganisation,
        "Type of Organization": bank.TypeOFOrganisation,
        "Nature of Business": bank.NatureOfBusiness,
        "Registration Country": bank.RegisterCountry,
        "Registration Address": bank.RegisterOfficeAddress,
        "Registration City": bank.RegisterOfficeCity,
        "Registration Zip Code": bank.RegsterZipCode,
        "Registration Number (Incorporation No.)": bank.Registrationumber,
        "Tax Number": bank.TaxNumber,
        "Date of Establishment": bank.DateOFEstablishment,
        "Country of Incorporation": bank.ContryofInCorporation,
        "Contact Number": bank.ContactNumber,
        "Communication Email": bank.OfficailEmail,
        "Authorized Signatory 1 Name":
          bank.AuthorizedSignatory[0]?.AuthorizedFullNAme,
        "Authorized Signatory 1 Email":
          bank.AuthorizedSignatory[0]?.Authorizedemail,
        "Authorized Signatory 1 Contact No.":
          bank.AuthorizedSignatory[0]?.AuthorizedContactNumber,
        "Authorized Signatory 1 Passport":
          bank.AuthorizedSignatory[0]?.AuthorizedPassportNo,
        "Authorized Signatory 1 PAN":
          bank.AuthorizedSignatory[0]?.AuthorizedPanNo,
        "Authorized Signatory 2 Name":
          bank.AuthorizedSignatory[1]?.AuthorizedFullNAme,
        "Authorized Signatory 2 Email":
          bank.AuthorizedSignatory[1]?.Authorizedemail,
        "Authorized Signatory 2 Contact No.":
          bank.AuthorizedSignatory[1]?.AuthorizedContactNumber,
        "Authorized Signatory 2 Passport":
          bank.AuthorizedSignatory[1]?.AuthorizedPassportNo,
        "Authorized Signatory 2 PAN":
              bank.AuthorizedSignatory[1]?.AuthorizedPanNo,
              "Nominee 1 Name":
              bank.Nominees
              [0]?.
              NomineeFullNAme,
            "Nominee 1 Email":
              bank.Nominees
              [0]?.Nomineeemail,
            "Nominee 1 Contact No.":
              bank.Nominees
              [0]?.NomineeContactNumber,
            "Nominee 1 Passport":
              bank.Nominees
              [0]?.NomineePassportNo,
            "Nominee 1 PAN":
              bank.Nominees
              [0]?.NomineePanNo,
        "Account Type": "Business",
        "Currency": "USD",
        "Initial Deposit Amount": "$100000",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "BankDataList");
    XLSX.writeFile(wb, "BanksDataList.xlsx");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by company name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Company Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Spv Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Type of Organization
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nature of Business
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Registrtion Country
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Registrtion Address
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Registrtion City
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Registrtion Zip Code
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Registrtion Number
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Tax Number
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Date of Establishment
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Country of Incorporation
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Contact Number
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Official Communication Email
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1 Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1 Email
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1 Contact No.
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1 Passport
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 1 PAN
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2 Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2 Email
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2 Contact No.
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2 Passport
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Authorized Signatory 2 PAN
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 1 Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee1 Email
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee1 Contact No.
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 1 Passport
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 1 PAN
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 2 Name
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 2 Email
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 2 Contact No.
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
            Nominee 2 Passport
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Nominee 2 PAN
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Account Type
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Currency
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Initial Deposit Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredBanks.map((bank, index) => (
            <tr key={`row-${index}`}>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Company?.name}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.NameOfOrganisation}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.TypeOFOrganisation}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.NatureOfBusiness}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.RegisterCountry}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.RegisterOfficeAddress}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.RegisterOfficeCity}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.RegsterZipCode}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Registrationumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.TaxNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.DateOFEstablishment}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.ContryofInCorporation}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.ContactNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.OfficailEmail}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[0]?.AuthorizedFullNAme}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[0]?.Authorizedemail}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[0]?.AuthorizedContactNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[0]?.AuthorizedPassportNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[0]?.AuthorizedPanNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[1]?.AuthorizedFullNAme}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[1]?.Authorizedemail}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[1]?.AuthorizedContactNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[1]?.AuthorizedPassportNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.AuthorizedSignatory[1]?.AuthorizedPanNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[0]?.NomineeFullNAme}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[0]?.Nomineeemail}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[0]?.NomineeContactNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[0]?.NomineePassportNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[0]?.NomineePanNo}
              </td>
             
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[1]?.NomineeFullNAme}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[1]?.Nomineeemail}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[1]?.NomineeContactNumber}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[1]?.NomineePassportNo}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {bank.Nominees[1]?.NomineePanNo}
              </td>
             
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                Business
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>USD</td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                $100000
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bankforms;
