import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer/Footer";
import "./ClientEvent.css";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { getallEvents } from "../../redux/action/companyaction";
import { useNavigate, useLocation } from "react-router-dom";

const ClientEvents = () => {
  const [response, setResponse] = useState("");
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { companyid } = location.state || {}; // Retrieve companyid from location state

  useEffect(() => {
    const fetchData = async () => {
      const fetchedEvents = await dispatch(getallEvents());
      // Sort events by date in descending order and take the latest one
      const sortedEvents = fetchedEvents.sort((a, b) => new Date(b.date) - new Date(a.date));
      setEvents(sortedEvents);
    };
    fetchData();
  }, [dispatch]);

  const handleRadioChange = (event) => {
    setResponse(event.target.value);
  };

  const handleSubmit = (id) => {
    if (response === "No") {
      navigate("/userDashboard");
    } else {
      navigate(`/addguest/${id}`, { state: { companyid } });
    }
  };

  const latestEvent = events.length > 0 ? events[0] : null;

  return (
    <>
      <Header />
      <div className="eventmain" style={{backgroundColor:"#051E3F"}}>
        <div className="eventCard">
          {latestEvent ? (
            <Card className="cardWrapper">
              <div className="baneer">
                <Card.Img
                  className="cardImage"
                  style={{ width: "45vw" }}
                  variant="top"
                  src={latestEvent.banner.url}
                />
              </div>
              <Card.Body className="cardBody">
                <Card.Text>
                  Are you coming?
                  <div className="checkbox">
                    <div>
                      <input
                        type="radio"
                        id="yes"
                        name="response"
                        value="Yes"
                        checked={response === "Yes"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="no"
                        name="response"
                        value="No"
                        checked={response === "No"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                  <button
                    className="eventBtn primary"
                    onClick={() => handleSubmit(latestEvent._id)}
                  >
                    Submit
                  </button>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <p style={{color:"white"}}>Upcoming assembly will be shown here</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClientEvents;
