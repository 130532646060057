import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewDocuments } from "../../redux/action/companyaction";
import "./Contract.css"; // Import the new CSS file for responsive design
import Footer from "../Footer/Footer";
import Disclaimer from "../Disclaimer/Disclaimer";

const Contract = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handletoprofile = () => {
    navigate("/userDashboard");
  };
  const location = useLocation();
  const { Contract } = location.state || [];
  console.log("Contract", Contract);

  const handletoopenview = async (key) => {
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
      console.log("key", key);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="userContract" style={{backgroundColor:"#051E3F"}}>
      <button className="to" onClick={handletoprofile}>
        <IoMdArrowBack size={27} />
      </button>
      <div className="bottom">
        <h3>CONTRACT DETAILS</h3>

        {Contract?.map((item) => (
          <div className="contract" key={item._id}>
            <div className="main-type">
              <div className="type">
                <h4>TYPE OF CONTRACT</h4>
                <div className="details">{item.TypeOfContract}</div>
              </div>
              <div className="type">
                <h4>CONTRACT TENURE START DATE</h4>
                <div className="details">{item.StartContractTenure}</div>
              </div>
              <div className="type">
                <h4>CONTRACT TENURE END DATE</h4>
                <div className="details">{item.EndContractTenure}</div>
              </div>
            </div>
            <div className="main-type">
              <div style={
                {paddingTop:"1rem"}
              } className="type">
                <h4>CONTRACT VALUE (CR)</h4>
                <div className="details">{item.ContractValue}</div>
              </div>
              <div className="type">
                <h4>EMD AMOUNT</h4>
                <div className="details">{item.EmdAmount}</div>
              </div>
            </div>
            <div className="main-type">
              <div className="type">
                <h4>MOU</h4>
                {item.Mou?.url ? (
                  <button
                    className="preview"
                    style={{width:"10rem"}}
                    onClick={() => handletoopenview(item.Mou.key)}
                  >
                    Preview
                  </button>
                ) : (
                  <span>Not available</span>
                )}
              </div>
            </div>
          </div>
        ))}
        </div>
        
      </div>
      <Disclaimer/>

      <Footer/>
    </>
  );
};

export default Contract;
