import React, { useState } from "react";
import "../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Form, Spinner } from "react-bootstrap"; // Import Spinner
import { useDispatch } from "react-redux";
import { handlePayment } from "../redux/action/companyaction";
import Sidebar from "./Sidebar/Sidebar";

const AddPayment = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const dispatch = useDispatch();

  const [payments, setPayments] = useState("");
  const [received, setReceived] = useState("Pending");
  const [receipt, setReceipt] = useState(null);
  const [paymentDate, setPaymentDate] = useState(""); // New state for date
  const [loading, setLoading] = useState(false); // Loading state

  const back = () => {
    navigate("/profile");
  };

  const Backhandler = () => {
    navigate(`/AddContract/${companyId}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (payments.trim() === "") {
      alert("Payment amount is required");
      return;
    }
    if (!paymentDate) {
      alert("Payment date is required");
      return;
    }

    const formData = new FormData();
    formData.append("payment", payments);
    formData.append("received", received);
    formData.append("date", paymentDate); // Append the date to FormData
    if (receipt) {
      formData.append("file", receipt);
    }

    setLoading(true); // Set loading to true when form submission starts

    dispatch(handlePayment(formData, companyId))
      .then(() => {
        setLoading(false); // Set loading to false after submission
        navigate("/admin/profile");
      })
      .catch((error) => {
        setLoading(false); // Set loading to false if submission fails
        console.error("Failed to add payment:", error);
        alert("Failed to add payment. Please try again.");
      });
  };

  return (
    <div className="all">
      <Sidebar />
      <div className="container" style={{ width: "70vw" }}>
        <h3>Add Payments</h3>
        <hr />
        <Form onSubmit={submitHandler} encType="multipart/form-data">
          <Form.Group controlId="formGridPayment" className="mb-3">
            <Form.Label>Payment</Form.Label>
            <Form.Control
              type="number"
              value={payments}
              onChange={(e) => setPayments(e.target.value)}
              placeholder="Amount"
              required
            />
          </Form.Group>

          <Form.Group controlId="formGridReceipt" className="mb-3">
            <Form.Label>Receipt</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setReceipt(e.target.files[0])}
              accept="image/*,application/pdf"
            />
          </Form.Group>

          <Form.Group controlId="formGridReceived" className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={received}
              onChange={(e) => setReceived(e.target.value)}
              required
            >
              <option value="Pending">Pending</option>
              <option value="Received">Received</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formGridDate" className="mb-3">
            <Form.Label>Payment Date</Form.Label>
            <Form.Control
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              placeholder="Select Date"
              required
            />
          </Form.Group>

          <button className="privews" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Finish"}
          </button>
          <button className="privews" type="button" onClick={Backhandler} disabled={loading}>
            Back
          </button>
        </Form>
      </div>
    </div>
  );
};

export default AddPayment;
