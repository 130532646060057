import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./Qualification.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer";
import logo from "../logo-vpvv.png";
import {
  createDirectorEducation,
  createEducation,
  createRefund,
  getmyclient,
} from "../../redux/action/companyaction";
import { Spinner } from "react-bootstrap";

const QualificationForm = () => {
  const navigate = useNavigate();
  const [selectedDirector, setSelectedDirector] = useState("");
  const [childrenCount, setChildrenCount] = useState(0);
  const [children, setChildren] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [nameee, setNameee] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMarried, setIsMarried] = useState(false); // New state for marital status
  const [studyAbroad, setStudyAbroad] = useState(null); // New state for studying abroad
  const [willing, setWilling] = useState(null); // New state for studying abroad
  const dispatch = useDispatch();
  const { id } = useParams();
  const [qualification, setQualification] = useState("");
  const [ind, setInd] = useState(false);
  //Single Director

  const handleQualificationsChange = (e) => {
    setQualification(e.target.value);
  };
  const handleQualificationChange = (e) => {
    setQualification(e.target.value);
  };

  useEffect(() => {
    const getMyProfile = async () => {
      const response = await dispatch(getmyclient());
      console.log(response);

      if (response.individual === "true") {
        setNameee(response.name);
        setInd(true);
      } else {
        setDirectors(response.SpvDirectors);
      }
    };
    getMyProfile();
  }, [dispatch, navigate]);

  const handleDirectorChange = (e) => {
    setSelectedDirector(e.target.value);
  };

  const handleChildrenCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    if (isNaN(count) || count < 0 || count > 10) return;
    setChildrenCount(count);
    setChildren(
      Array.from({ length: count }).map(() => ({
        firstName: "",
        middleName: "",
        lastName: "",
        studyAbroad: willing,
        qualification: "",
        cert10: "",
        marksheet10: "",
        cert12: "",
        marksheet12: "",
        degreeName: "",
        year: "",
        semester: "",
        other: "",
        certGraduate: "",
        marksheetGraduate: "",
        certPostGraduate: "",
        marksheetPostGraduate: "",
        otherQualification:""
      }))
    );
  };

  const handleInputChange = (index, e) => {
    const { name, type } = e.target;
    const updatedChildren = [...children];

    if (type === "file") {
      const file = e.target.files[0]; // Get the uploaded file
      updatedChildren[index] = {
        ...updatedChildren[index],
        [name]: file, // Store the file object instead of string value
      };
    } else {
      const { value, checked } = e.target;
      updatedChildren[index] = {
        ...updatedChildren[index],
        [name]: type === "checkbox" ? checked : value,
      };
    }
    setChildren(updatedChildren);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("NameOfDirector", selectedDirector);
      formData.append("companyId", id);

      // Iterate through each child and append their details to formData
      children.forEach((child, index) => {
        formData.append(`children[${index}][NameOfchild]`, child.firstName);
        formData.append(`children[${index}][middleName]`, child.middleName); 
        formData.append(`children[${index}][lastName]`, child.lastName);
        formData.append(`children[${index}][studyAbroad]`, willing);
        formData.append(`children[${index}][class]`, child.otherQualification);

        formData.append(
          `children[${index}][qualification]`,
          child.qualification
        );

        // Always append the 10th and 12th certificates if they exist
        if (child.cert10) {
          formData.append(`higherEducationCertificate`, child.cert10);
        }
        if (child.marksheet10) {
          formData.append(`higherEducationMarksSheet`, child.marksheet10);
        }

        if (child.cert12) {
          formData.append(`SeniorEducationCertificate`, child.cert12);
        }
        if (child.marksheet12) {
          formData.append(`SeniorEducationMarksheet`, child.marksheet12);
        }
        if (child.otherQualification) {
          formData.append(`class`, child.otherQualification);
          
        }
        // Append other qualification-related fields based on the selected qualification
        if (child.qualification === "Undergraduate") {
          formData.append(`degreeName`, child.degreeName); // For Undergraduate and above
          formData.append(`year`, child.year); // Append graduation year for Undergraduate and above
          formData.append(`semester`, child.semester);
          formData.append(
            `UnderGraduationMarksheet`,
            child.marksheetUndergraduate
          );
        }
        if (child.qualification === "Graduate") {
          formData.append(`GraduationCertificate`, child.certGraduate);
          formData.append(`GraduationMarksheet`, child.marksheetGraduate);
        }
        if (child.qualification === "PostGraduate") {
          formData.append(`PostGraduationCertificate`, child.certPostGraduate);
          formData.append(
            `PostGraduationMarksheet`,
            child.marksheetPostGraduate
          );
        }
      });

      console.log(formData); // To check if all files are being appended

      const result = await dispatch(createEducation(formData, id, navigate));
      console.log("Submission Result:", result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to submit form:", error);
      alert("Failed to submit. Please try again.");
    }
  };

  return (
    <>
      <div className="refundall">
        <img src={logo} alt="logo" />
        <h2 style={{ color: "white" }}>
          VPVV TECHNO CONSTRUCTION PRIVATE LIMITED
        </h2>
        <h3 style={{ color: "white" }}>EDUCATION</h3>
        <div className="refuldrow">
          <div className="refundbox">
            <Form>
              <Form.Group className="mb-3" controlId="directorSelect">
                <Form.Label>Select Director</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedDirector}
                  onChange={handleDirectorChange}
                  required
                >
                  <option value="">Select Director</option>
                  {ind === true ? (
                    <option value={nameee}>{nameee}</option>
                  ) : (
                    directors.map((director, index) => (
                      <option key={index} value={director.DirectorName}>
                        {director.DirectorName}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>

              {/* Children Count Selection */}

              <Form.Group className="mb-3" controlId="childrenCount">
                <Form.Label>No. of Children</Form.Label>
                <Form.Control
                  as="select"
                  value={childrenCount}
                  onChange={handleChildrenCountChange}
                >
                  <option value="">Select Number of Children</option>
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          {/* Children Form Start Here............................ */}

          <div className="refundform">
            <Form onSubmit={handleSubmit}>
              {children.map((child, index) => (
                <div key={index} className="refund-entry">
                  <Form.Group
                    className="mb-3"
                    controlId={`childFirstName-${index}`}
                  >
                    <Form.Label>Full Name Of Your Ward</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Enter Your Full Name"
                      value={child.firstName}
                      required
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId={`studyAbroad-${index}`}
                  >
                    <Form.Check
                      type="checkbox"
                      name="wiiling"
                      label="Are You Willing To Send Your Ward To Abroad For Higher Education?"
                      onChange={(e) => setWilling(true)}
                    />
                  </Form.Group>

                  {willing === true && (
                    <Form.Group
                      className="mb-3"
                      controlId={`qualification-${index}`}
                    >
                      <Form.Label>Qualification</Form.Label>
                      <Form.Control
                        as="select"
                        name="qualification"
                        value={child.qualification}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option value="">Select Qualification</option>
                        <option value="10th">10th</option>
                        <option value="12th">12th</option>
                        <option value="Undergraduate">Undergraduate</option>
                        <option value="Graduate">Graduate</option>
                        <option value="PostGraduate">Post Graduate</option>
                        <option value="other">Other</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  {child.qualification === "other" && (
                    <Form.Group
                      className="mb-3"
                      controlId={`otherQualification-${index}`}
                    >
                      <Form.Label>Please specify your qualification</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your qualification"
                        name="otherQualification"
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </Form.Group>
                  )}

                  {/* Always show 10th documents if qualification is 12th */}
                  {child.qualification === "10th" && (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId={`cert10-${index}`}
                      >
                        <Form.Label>10th Certificate</Form.Label>
                        <Form.Control
                          type="file"
                          name="cert10"
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </>
                  )}

                  {/* <Form.Group
                    className="mb-3"
                    controlId={`marksheet10-${index}`}
                  >
                    <Form.Label>10th Marksheet</Form.Label>
                    <Form.Control
                      type="file"
                      name="marksheet10"
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </Form.Group> */}

                  {/* Show 12th documents if qualification is 12th */}
                  {child.qualification === "12th" && (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId={`cert10-${index}`}
                      >
                        <Form.Label>10th Certificate</Form.Label>
                        <Form.Control
                          type="file"
                          name="cert10"
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId={`cert12-${index}`}
                      >
                        <Form.Label>12th Certificate</Form.Label>
                        <Form.Control
                          type="file"
                          name="cert12"
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </>
                  )}

                  {/* Logic for other qualifications */}
                  {["Undergraduate", "Graduate", "PostGraduate"].includes(
                    child.qualification
                  ) && (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId={`cert10-${index}`}
                      >
                        <Form.Label>10th Certificate</Form.Label>
                        <Form.Control
                          type="file"
                          name="cert10"
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId={`cert12-${index}`}
                      >
                        <Form.Label>12th Certificate</Form.Label>
                        <Form.Control
                          type="file"
                          name="cert12"
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>

                      {child.qualification === "Undergraduate" && (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId={`degreeName-${index}`}
                          >
                            <Form.Label>Degree Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="degreeName"
                              placeholder="Enter Degree Name"
                              value={child.degreeName}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId={`year-${index}`}
                          >
                            <Form.Label>Year of Graduation</Form.Label>
                            <Form.Control
                              type="text"
                              name="year"
                              placeholder="Enter Year of Graduation"
                              value={child.year}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId={`semester-${index}`}
                          >
                            <Form.Label>Semester</Form.Label>
                            <Form.Control
                              type="text"
                              name="semester"
                              placeholder="Enter Semester"
                              value={child.semester}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId={`marksheetUndergraduate-${index}`}
                          >
                            <Form.Label>Undergraduate Marksheet</Form.Label>
                            <Form.Control
                              type="file"
                              name="marksheetUndergraduate"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                        </>
                      )}

                      {child.qualification === "Graduate" && (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId={`certGraduate-${index}`}
                          >
                            <Form.Label>Graduate Certificate</Form.Label>
                            <Form.Control
                              type="file"
                              name="certGraduate"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId={`marksheetGraduate-${index}`}
                          >
                            <Form.Label>Graduate Marksheet</Form.Label>
                            <Form.Control
                              type="file"
                              name="marksheetGraduate"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                        </>
                      )}

                      {child.qualification === "PostGraduate" && (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId={`certPostGraduate-${index}`}
                          >
                            <Form.Label>Post Graduate Certificate</Form.Label>
                            <Form.Control
                              type="file"
                              name="certPostGraduate"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId={`marksheetPostGraduate-${index}`}
                          >
                            <Form.Label>Post Graduate Marksheet</Form.Label>
                            <Form.Control
                              type="file"
                              name="marksheetPostGraduate"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QualificationForm;
