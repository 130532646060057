import React, { useState } from "react";
import "../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Form, Row, Col } from "react-bootstrap";
import { addContract } from "../redux/action/companyaction";
import { useDispatch } from "react-redux";
import Sidebar from "./Sidebar/Sidebar";

const AddContract = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [typeOfContract, setTypeOfContract] = useState("");
  const [contractTenureStart, setContractTenureStart] = useState("");
  const [contractTenureEnd, setContractTenureEnd] = useState("");
  const [contractValue, setContractValue] = useState("");
  const [emdAmount, setEmdAmount] = useState("");
  const [mou, setMou] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const back = () => {
    navigate("/profile");
  };

  const nextHandler = () => {
    navigate(`/AddPayments/${companyId}`);
  };

  const backHandler = () => {
    navigate(`/UploadDocuments/${companyId}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("TypeOfContract", typeOfContract);
    formData.append("StartContractTenure", contractTenureStart);
    formData.append("EndContractTenure", contractTenureEnd);
    formData.append("ContractValue", contractValue);
    formData.append("EmdAmount", emdAmount);
    formData.append("files", mou);

    dispatch(addContract(formData, companyId))
      .then(() => {
        // Reset form fields after successful upload
        setTypeOfContract("");
        setContractTenureStart("");
        setContractTenureEnd("");
        setContractValue("");
        setEmdAmount("");
        setMou(null);
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error uploading data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="all">
      <Sidebar/>
      <div className="container" style={{width:"70vw"}}>
        <h3>Add Contract</h3>
        <hr />
        <Form onSubmit={submitHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTypeOfContract">
              <Form.Label>Type Of Contract</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Type of Contract"
                value={typeOfContract}
                onChange={(e) => setTypeOfContract(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridStartContractTenure">
              <Form.Label>Contract Tenure Start Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Start Date (DD-MM-YYYY)"
                value={contractTenureStart}
                onChange={(e) => setContractTenureStart(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEndContractTenure">
              <Form.Label>Contract Tenure End Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter End Date (DD-MM-YYYY)"
                value={contractTenureEnd}
                onChange={(e) => setContractTenureEnd(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridContractValue">
              <Form.Label>Contract Value</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Contract Value"
                value={contractValue}
                onChange={(e) => setContractValue(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmdAmount">
              <Form.Label>EMD Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter EMD Amount"
                value={emdAmount}
                onChange={(e) => setEmdAmount(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMou">
              <Form.Label>MOU</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setMou(e.target.files[0])}
                required
              />
            </Form.Group>
          </Row>
          <button className="privews" type="submit" disabled={loading}>
            {loading ? "Adding..." : "Add"}
          </button>
          <button className="privews" type="button" onClick={nextHandler}>
            Next
          </button>
          <button className="privews" type="button" onClick={backHandler}>
            Back
          </button>
        </Form>
      </div>
    </div>
  );
};

export default AddContract;
