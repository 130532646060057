import React from "react";
import "./temp.css";
import Footer from "./Footer/Footer";
import logo from "./logo-vpvv.png";
import { TbLogout2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Temp = () => {
  const navigate = useNavigate();
  const handletoLogout = () => {
    navigate("/userLogin");
  };
  return (
    <>
      {/* <button className="logout-button" onClick={handletoLogout}>
        <TbLogout2 size={30} /> Logout
      </button> */}

      <div className="tempall" style={{ margin: "2rem" }}>
        <h2>Thank you for logging in VPVV</h2>

        <img src={logo} alt="" />

        <div className="tempcardd">
          <div className="tempmessage">
            <div className="temph">
              <h3 style={{color:"black"}}>Your Entry has been logged in the system.</h3>
            </div>
            <h3 style={{color:"black"}}>
              Your profile and data will be syncronized with the Agency and
              updated in the Portal shortly.
            </h3>
            <p>If any further queries, please Contact the Undersigned</p>
            <h6>Rahul Nair</h6>
            <h6>Rahul.nair@vpvvindia.com</h6>
            <h6>+91-995-852-6926.</h6>
          </div>
        </div>
      </div>

      <div className="foooooo" style={{ marginTop: "3rem" }}>
      </div>
    </>
  );
};

export default Temp;
