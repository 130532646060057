import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./Refund.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer";
import logo from "../logo-vpvv.png";
import { createRefund } from "../../redux/action/companyaction";
import { Spinner } from "react-bootstrap";

const Refund = () => {
  const navigate = useNavigate();
  const [refundCount, setRefundCount] = useState(0);
  const [refunds, setRefunds] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams(); // Get id from the URL params

  const handleRefundCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    if (isNaN(count) || count < 0 || count > 10) return; // Restrict between 0 and 10

    setRefundCount(count);
    setRefunds(
      Array.from({ length: count }).map(() => ({
        amount: "",
        accountNumber: "",
        accountName: "",
        ifscCode: "",
        branchAddress: "",
        bankName: "",
        remarks: "",
      }))
    );
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRefunds = [...refunds];
    updatedRefunds[index] = {
      ...updatedRefunds[index],
      [name]: value,
    };
    setRefunds(updatedRefunds);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("refundCount", refundCount);
      formData.append("companyId", id); // Append company ID

      refunds.forEach((refund, index) => {
        formData.append(`refunds[${index}][Amount]`, refund.amount);
        formData.append(`refunds[${index}][AccountNumber]`, refund.accountNumber);
        formData.append(`refunds[${index}][AccountName]`, refund.accountName);
        formData.append(`refunds[${index}][IfscCode]`, refund.ifscCode);
        formData.append(`refunds[${index}][BranchAddress]`, refund.branchAddress);
        formData.append(`refunds[${index}][BankName]`, refund.bankName);
        formData.append(`refunds[${index}][Remarks]`, refund.remarks);
      });

      // Dispatch the action and send formData along with the company ID
      const result = await dispatch(createRefund(formData, id,navigate));
      console.log("Submission Result:", result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to add payment:", error);
      alert("Failed to add Bank Account Information. Please try again.");
    }
  };

  return (
    <>
      <div className="refundall">
        <img src={logo} alt="logo" />
        <h2 style={{ color: "white" }}>VPVV TECHNO CONSTRUCTION PRIVATE LIMITED</h2>
        <h3 style={{ color: "white" }}>EMD Reimbursement</h3>
        <div className="refuldrow">
          <div className="refundbox">
            <Form>
              <Form.Group className="mb-3" controlId="refundCount">
                <Form.Label>SELECT THE NUMBER OF SOURCE ACCOUNT FROM WHERE EMD HAS BEEN DEPOSITED TO VPVV</Form.Label>
                <Form.Control
                  as="select"
                  value={refundCount}
                  onChange={handleRefundCountChange}
                >
                  <option value="">Select Number of Accounts</option>
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>

          <div className="refundform">
            <Form onSubmit={handleSubmit}>
              {refunds.map((refund, index) => (
                <div key={index} className="refund-entry">
                  <div className="row">
                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId={`amount-${index}`}>
                        <Form.Label>Amount*</Form.Label>
                        <Form.Control
                          type="number"
                          name="amount"
                          placeholder="Enter Amount"
                          required
                          value={refund.amount}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId={`accountNumber-${index}`}>
                        <Form.Label>Account No.*</Form.Label>
                        <Form.Control
                          type="number"
                          name="accountNumber"
                          placeholder="Enter Account No."
                          value={refund.accountNumber}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId={`accountName-${index}`}>
                        <Form.Label>Account Holder's Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="accountName"
                          placeholder="Enter Account Name"
                          value={refund.accountName}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId={`ifscCode-${index}`}>
                        <Form.Label>IFSC Code*</Form.Label>
                        <Form.Control
                          type="text"
                          name="ifscCode"
                          placeholder="Enter IFSC Code"
                          value={refund.ifscCode}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId={`branchAddress-${index}`}>
                        <Form.Label>Branch Address*</Form.Label>
                        <Form.Control
                          type="text"
                          name="branchAddress"
                          placeholder="Enter Branch Address"
                          value={refund.branchAddress}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-3">
                      <Form.Group className="mb-3" controlId={`bankName-${index}`}>
                        <Form.Label>Bank Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="bankName"
                          placeholder="Enter Bank Name"
                          value={refund.bankName}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId={`remarks-${index}`}>
                        <Form.Label>Remarks*</Form.Label>
                        <Form.Control
                          type="text"
                          name="remarks"
                          placeholder="Enter Remarks"
                          value={refund.remarks}
                          required
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                variant="primary"
                type="submit"
                className="form-submit-btn"
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Finish"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <hr
        style={{
          margin: "0",
          backgroundColor: "#cca857",
          color: "#cca857",
          height: "0rem",
        }}
      />
      <Footer />
    </>
  );
};

export default Refund;
