import React, { useState } from "react";
import "../otp/Otp.css";
import logo from "../logo-vpvv.png";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SendOtp, SentOtp } from "../../redux/action/companyaction";
import { AdminSendOtp } from "../../redux/action/useravtion";

const AdminOtp = () => {
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const dispatch = useDispatch();
  const lowercasemail = email.toLowerCase()

  const otpHandler = async (way, navigate) => {
    setLoading(true); // Set loading to true when button is clicked
    try {
        await dispatch(AdminSendOtp(lowercasemail, navigate));
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setLoading(false); // Set loading to false after the action is complete
    }
  };

  return (
    <div className="full">
      <img style={{width:"13rem"}} src={logo} alt="logo" />
      <div className="box">
        <Row className="justify-content-center">
          <Col xs={10} md={6} className="containeraa">
            <h1 className="text-center mt-3">OTP Authentication</h1>
            <div className="otp">
              <button onClick={() => otpHandler("email", navigate)} disabled={loading}>
                {loading ? <span className="spinner"></span> : "Send OTP via Email"}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminOtp;
