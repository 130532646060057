import React, { useEffect, useState } from "react";
import "./TrainingModule.css";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTrainingmodule } from "../../redux/action/companyaction";

const TrainingcreateModule = () => {
  const [formData, setFormData] = useState({
    Companyname: "",
    companyId: "",
    Trainee1: "",
    Trainee2: "",
    TrainingDate: "",
    APO: "",
    Type: "",
    Zone: "",
    Level: "",
    AssignedUniform: "",
    TrainerAssigned: "",
    TrainerName: "",
    TrainerDesignation: "",
    Commands: "",
    Sector: "",
    Status: ""  // New field for Status
  });

  const location = useLocation();
  const { id } = useParams(); // Extract company ID from URL parameters
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state?.Companyname) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyId: id,
        Companyname: location.state.Companyname,
      }));
    }
  }, [id, location.state?.Companyname]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Dispatch the form data to the action
    dispatch(createTrainingmodule(formData));

    // Reset form data after submission
    setFormData({
      Companyname: "",
      companyId: id,
      Trainee1: "",
      Trainee2: "",
      TrainingDate: "",
      APO: "",
      Type: "",
      Zone: "",
      Level: "",
      AssignedUniform: "",
      TrainerAssigned: "",
      TrainerName: "",
      TrainerDesignation: "",
      Commands: "",
      Sector: "",
      Status: ""  // Reset Status
    });
  };

  return (
    <div className="moduleform">
      <div className="moduleheading">
        <h3>Create Training Module Form</h3>
      </div>
      <form className="moduletrainbox" onSubmit={handleSubmit}>
        <label>
          Company Name:
          <input
            type="text"
            name="Companyname"
            value={formData.Companyname}
            onChange={handleChange}
            readOnly
          />
        </label>
        <label>
          Trainee 1:
          <input
            type="text"
            name="Trainee1"
            value={formData.Trainee1}
            onChange={handleChange}
          />
        </label>
        <label>
          Trainee 2:
          <input
            type="text"
            name="Trainee2"
            value={formData.Trainee2}
            onChange={handleChange}
          />
        </label>
        <label>
          Training Date:
          <input
            type="date"
            name="TrainingDate"
            value={formData.TrainingDate}
            onChange={handleChange}
          />
        </label>
        <label>
          APO/FPO:
          <input
            type="text"
            name="APO"
            value={formData.APO}
            onChange={handleChange}
          />
        </label>
        <label>
          Type:
          <input
            type="text"
            name="Type"
            value={formData.Type}
            onChange={handleChange}
          />
        </label>
        <label>
          Zone:
          <input
            type="text"
            name="Zone"
            value={formData.Zone}
            onChange={handleChange}
          />
        </label>
        <label>
          Assigned Uniform:
          <input
            type="text"
            name="AssignedUniform"
            value={formData.AssignedUniform}
            onChange={handleChange}
          />
        </label>
        <label>
          Trainer Assigned:
          <input
            type="text"
            name="TrainerAssigned"
            value={formData.TrainerAssigned}
            onChange={handleChange}
          />
        </label>
        <label>
          Trainer Name:
          <input
            type="text"
            name="TrainerName"
            value={formData.TrainerName}
            onChange={handleChange}
          />
        </label>
        <label>
          Trainer Designation:
          <input
            type="text"
            name="TrainerDesignation"
            value={formData.TrainerDesignation}
            onChange={handleChange}
          />
        </label>
        <label>
          Commands:
          <input
            type="text"
            name="Commands"
            value={formData.Commands}
            onChange={handleChange}
          />
        </label>
        <label>
          Sector:
          <select name="Sector" value={formData.Sector} onChange={handleChange}>
            <option value="">Select Sector</option>
            <option value="IT">IT</option>
            <option value="Construction">Construction</option>
            <option value="21">21</option>
          </select>
        </label>
        <label>
          Level:
          <select name="Level" value={formData.Level} onChange={handleChange}>
            <option value="">Select Level</option>
            <option value="Advanced">Advanced</option>
            <option value="Intermediate">Intermediate</option>
          </select>
        </label>
        <label>
          Status: {/* New Status field */}
          <select name="Status" value={formData.Status} onChange={handleChange}>
            <option value="">Select Status</option>
            <option value="Active">Active</option>
            <option value="Planned">Planned</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="On Hold">On Hold</option>
          </select>
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TrainingcreateModule;
