import React, { useState } from "react";
import "./Edit.css";
import { useLocation, useParams } from "react-router-dom";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import {
  addDirectors,
  editIndividual,
  updateDirectors,
} from "../../redux/action/companyaction";
import { useDispatch } from "react-redux";


const EditIndividual = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);
  const [aadharCard, setAadharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [passport, setPassport] = useState(null); // State for Passport
  const [voter, setVoter] = useState(null); // State for Passport
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const submithandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("photo", photo);
    formData.append("AAdhar", panCard);
    formData.append("Pan", aadharCard);
    formData.append("Passport", passport);
    formData.append("VoterId", voter);
    dispatch(editIndividual(formData, id))
      .then(() => {
        // Reset form fields after successful upload
        setName("");
        setEmail("");
        setPhoto(null);
        setAadharCard(null);
        setPanCard(null);
          setPassport(null);
          setVoter(null);// Reset Passport field
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error uploading data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleFileChange = (setter) => (e) => {
    setter(e.target.files[0]);
  };
  return (
    <>
      <div className="updateall">
        <div className="container" style={{ width: "70vw" }}>
          <h2>Edit Individual </h2>
          <hr />
          <Form onSubmit={submithandler}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </Form.Group>
            </Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Profile Photo</Form.Label>
              <Form.Control type="file" onChange={handleFileChange(setPhoto)} />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Aadhar Card</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange(setAadharCard)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>PAN Card</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange(setPanCard)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Passport</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange(setPassport)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Voter Id</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange(setVoter)}
              />
            </Form.Group>
            <button className="privews" type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="warning"
                  style={{ marginRight: "5px" }}
                />
              ) : null}
              Add
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditIndividual;
