  import React, { useState } from "react";
  import "./Registerstaff.css"
  import logo from "../logo-vpvv.png";
  import { useDispatch } from "react-redux";
  import { register } from "../../redux/action/useravtion";
  import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
  import { Link } from "react-router-dom";

  const RegisterStaff = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [team, setTeam] = useState(""); // State for team selection
    const [photo, setPhoto] = useState(null); // State for profile photo
    const [aadharCard, setAadharCard] = useState(null); // State for Aadhar card
    const [panCard, setPanCard] = useState(null); // State for PAN card
    const [passport, setPassport] = useState(null); // State for passport
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const submitHandler = async (e) => {
      e.preventDefault();
      setLoading(true);

      // Create a FormData object
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("team", team);

      // Append files only if they are selected
      if (photo) formData.append("photo", photo);
      if (aadharCard) formData.append("aadharCard", aadharCard);
      if (panCard) formData.append("panCard", panCard);
      if (passport) formData.append("passport", passport);

      // Dispatch the action with FormData
      await dispatch(register(formData));

      setLoading(false);
    };

    return (
      <div className="stafff">
        <div className="vpvv">
        <img src={logo} alt="logo" />
        </div>
        
        <div className="box">
          <Row className="justify-content-center">
            <Col xs={10} md={6} className="containeraa">
              <h1 className="text-center mt-3">Register</h1>
              <Form onSubmit={submitHandler} encType="multipart/form-data">
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    placeholder="Enter Your Full Name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Select Team</Form.Label>
                  <Form.Control
                    as="select"
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                    required
                  >
                    <option value="">Choose...</option>
                    <option value="Media">Media</option>
                    <option value="Accounts">Accounts</option>
                    <option value="IT">IT</option>
                    <option value="HR">HR</option>
                    <option value="Record">Record Keeping</option>
                    <option value="Others">Others</option>
                  </Form.Control>
                </Form.Group>
                {/* File Uploads */}
                <Form.Group>
                  <Form.Label>Profile Photo</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    accept="image/*"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Aadhar Card</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setAadharCard(e.target.files[0])}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>PAN Card</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPanCard(e.target.files[0])}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Passport</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setPassport(e.target.files[0])}
                  />
                </Form.Group>
                <Button className="mt-4" type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only"> Registering...</span>
                    </>
                  ) : (
                    "Register"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  export default RegisterStaff;
