import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../Styles/Register.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { registerCompany } from "../redux/action/companyaction";
import Sidebar from "./Sidebar/Sidebar";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);

  const back = () => {
    navigate("/profile");
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("companylogo", logo);
    const companyId = await dispatch(registerCompany(formData));
    if (companyId) {
      navigate(`/admin/addspvv/${companyId}`);
    }
    setLoading(false);
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  return (
    <div className="all">
      <Sidebar/>
      <div className="container" style={{width:"70vw"}}>
        <h3>Register Parent Company</h3>
        <hr />
        <Form onSubmit={submithandler}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Company Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Company Logo</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <button className="privews" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Add"}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
