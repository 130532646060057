import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Faq = () => {
  return (
      <>
          <Sidebar/>
          Faq
      
    </>
  )
}

export default Faq
