import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Notification.css";
import { TfiWrite } from "react-icons/tfi";
import { IoEyeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import NotificationModal from "./modal";
import { useDispatch } from "react-redux";
import {
  createNotification,
  deleteNotification,
  getAllNotifications,
} from "../../redux/action/companyaction";

const Notification = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [deletingId, setDeletingId] = useState(null); // New state for tracking the deleting notification ID

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await dispatch(getAllNotifications());
      setNotifications(response);
    };

    fetchNotifications();
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("files", e.target.file.files[0]); // Get the file from the input element
    formData.append("title", e.target.title.value); // Get the title from the input element

    await dispatch(createNotification(formData));

    setLoading(false);
    setShowModal(false);

    // Refresh notifications after creation
    const response = await dispatch(getAllNotifications());
    if (response && Array.isArray(response)) {
      setNotifications(response);
    } else {
      console.error("Failed to fetch notifications, received:", response);
      setNotifications([]);
    }
  };

  const deleteHandler = async (id) => {
    setDeletingId(id); // Set the ID of the notification being deleted

    await dispatch(deleteNotification(id));

    // Refresh notifications after deletion
    const response = await dispatch(getAllNotifications());
    if (response && Array.isArray(response)) {
      setNotifications(response);
    } else {
      console.error("Failed to fetch notifications, received:", response);
      setNotifications([]);
    }

    setDeletingId(null); // Reset deleting ID after the process is complete
  };

  return (
    <div
      className="adminnotification"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Sidebar />
      <div className="notificatiionall">
        <div className="notificatonbox">
          {notifications && notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div className="notificationCard" key={index}>
                <div className="title" style={{width:"25rem", overflow:"hidden"}}>
                  <p>{notification.title}</p>
                </div>
                <div className="Notificationlimit">
                 
                  <a href={notification.pdf.url}>
                    <button>
                      <IoEyeSharp />
                      View
                    </button>
                  </a>
                  <button
                    style={{ width: "2rem" }}
                    onClick={() => deleteHandler(notification._id)}
                    disabled={deletingId === notification._id} // Disable button during deletion
                  >
                    {deletingId === notification._id ? (
                      <div className="spinner"></div> // Show spinner during deletion
                    ) : (
                      <MdDelete size={20} />
                    )}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No notifications found.</p>
          )}
        </div>
        <div className="createnotifiction">
          <button onClick={handleOpenModal}>Create Notification</button>
          <NotificationModal
            show={showModal}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
