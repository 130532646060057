import React, { useState } from 'react';
import './newsModal.css'; // Style the modal as needed

const NewsModal = ({ show, onClose, onSubmit, loading }) => {
  const [headline, setHeadline] = useState('');
  const [pdf, setPdf] = useState(null);
  const [date, setDate] = useState('');

  const handleFileChange = (e) => {
    setPdf(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('headline', headline);
    formData.append('file', pdf);
    formData.append('Date', date);
    onSubmit(formData);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Add News</h2>
        <form onSubmit={handleSubmit}>
          <div className="formGroup">
            <label>Headline</label>
            <input
              type="text"
              value={headline}
              onChange={(e) => setHeadline(e.target.value)}
              required
            />
          </div>
          <div className="formGroup">
            <label>Upload PDF</label>
            <input
              type="file"
              onChange={handleFileChange}
              required
            />
          </div>
          <div className="formGroup">
            <label>Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="modalActions">
            <button type="submit" disabled={loading}>
              {loading ? <span className="spinner"></span> : 'Submit'}
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsModal;
