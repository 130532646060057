import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./ClientEvent.css";
import { IoEyeSharp } from "react-icons/io5";
import { FaReplyAll } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { getHelp } from "../../redux/action/companyaction";
import { useDispatch } from "react-redux";
import HelpDetailsModal from "./HelpdetailModal"; // Import the custom HelpDetailsModal

const Help = () => {
  const dispatch = useDispatch();
  const [help, setHelp] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedHelp, setSelectedHelp] = useState(null);

  useEffect(() => {
    const fetchHelp = async () => {
      const response = await dispatch(getHelp());
      setHelp(response); // Assuming response is an array of help requests
      console.log("res", response);
    };
    fetchHelp();
  }, [dispatch]);

  const handleOpenDetailsModal = (item) => {
    setSelectedHelp(item);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedHelp(null);
  };

  const deleteHandler = async (id) => {
    // Implement delete logic if needed
  };

  return (
    <div className="allEvent">
      <Sidebar />
      <div className="events">
        <div className="eventbox" style={{ height: "100%" }}>
          {help?.map((item, index) => (
            <div key={index} className="eventCards">
              <div className="eventbanner" style={{ width: "7rem", height: "30px" }}>
                <p>{item.Email}</p> {/* Assuming each help item has an email field */}
              </div>
             
              <div className="eventlimit">
                <button style={{ width: "2rem" }}>
                  <FaReplyAll />
                </button>
                <button onClick={() => handleOpenDetailsModal(item)}>
                  <IoEyeSharp />
                  View
                </button>
                <button style={{ width: "2rem" }} onClick={() => deleteHandler(item._id)}>
                  <MdDelete size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedHelp && (
        <HelpDetailsModal
          show={showDetailsModal}
          onClose={handleCloseDetailsModal}
          help={selectedHelp}
        />
      )}
    </div>
  );
};

export default Help;
