import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import "./Deposit.css";

const Deposit = () => {
    const navigate = useNavigate();
    const handletoprofile = () => {
        navigate("/userDashboard");
    };

    const depositData = [
        { payment: '1000 USD', slip: 'https://example.com/slip1', date: '2024-08-20' },
        { payment: '500 USD', slip: 'https://example.com/slip2', date: '2024-08-15' }
    ];

    const handleSlipClick = (slipUrl) => {
        if (slipUrl) {
            window.open(slipUrl, "_blank");
        }
    };

    return (
        <>
            <div className="userDeposit">
                <button className="to" onClick={handletoprofile}>
                    <IoMdArrowBack size={27} />
                </button>
                <div className="bottom">
                    <h3 style={{ color: "white" }}>Deposit Details</h3>

                    {depositData.map((item, index) => (
                        <div className="deposit" key={index}>
                            <div className="main-type">
                                <div className="type">
                                    <h4>Payment</h4>
                                    <div className="details">{item.payment}</div>
                                </div>
                                <div className="type">
                                    <h4>Date</h4>
                                    <div className="details">{item.date}</div>
                                </div>
                            </div>
                            <div className="main-type">
                                <div className="type">
                                    <h4>Slip</h4>
                                    {item.slip ? (
                                        <button
                                            style={{width:"13rem"}}
                                            className="preview"
                                            onClick={() => handleSlipClick(item.slip)}
                                        >
                                            Preview
                                        </button>
                                    ) : (
                                        <span style={{ marginRight: "7px" }}>Not available</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Deposit;
