import React from "react";
import "./Disclaimer.css";
import { IoMdInformationCircleOutline } from "react-icons/io";


const Disclaimer = () => {
  return (
      <div className="disclaimer">
          <p>Disclaimer  <IoMdInformationCircleOutline /> </p>
      <p>
        The Documents or the Details which is showing as "Not Available" or the
        Documents which are not of High resolution, Kindly Send those
        Documents/Information on WebSupportT2@vpvvindia.com
      </p>
    </div>
  );
};

export default Disclaimer;
