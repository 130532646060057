import React, { useState, useEffect } from 'react';

const PdfFormViewer = () => {
  const [pdfUrl, setPdfUrl] = useState("https://vpvvpublic.s3.ap-south-1.amazonaws.com/Form+1_mod.pdf");
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [error, setError] = useState(null);

  // Function to fetch and display the PDF
  const loadPdf = async () => {
    try {
      const response = await fetch(pdfUrl);

      if (!response.ok) {
        throw new Error(`Failed to load PDF: ${response.statusText}`);
      }

      const blob = await response.blob();
      const dataUrl = URL.createObjectURL(blob);
      setPdfDataUrl(dataUrl);
    } catch (error) {
      console.error('Error loading the PDF:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    loadPdf();
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>PDF Form Viewer</h2>
      {error && <p style={styles.error}>Error: {error}</p>}
      
      {pdfDataUrl ? (
        <iframe
          src={pdfDataUrl}
          width="100%"
          height="100%"
          title="PDF Form"
          style={styles.iframe}
        />
      ) : (
        <p style={styles.loading}>Loading PDF...</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full height of the viewport
    width: '100vw',  // Full width of the viewport
    margin: 0,
    padding: 0,
    backgroundColor: '#f0f0f0',
  },
  header: {
    display: 'none',  // Hide the header to focus on the PDF view
  },
  iframe: {
    border: 'none',  // No border
    height: '100%',  // Take the full height available
    width: '100%',   // Take the full width available
    maxWidth: '100%', // Ensure it doesn’t overflow the screen
    maxHeight: '100%',
  },
  error: {
    color: 'red',
  },
  loading: {
    fontSize: '18px',
    color: '#555',
  },
};

export default PdfFormViewer;
