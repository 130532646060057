import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { IoEyeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { AddCharity, createCharity, getsingleCharity } from "../../redux/action/companyaction";
import { useNavigate, useParams } from "react-router-dom";

const ViewCharity = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [headline, setHeadline] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [charity, setCharity] = useState({});
  const { id } = useParams();
  
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCreateCharity = () => {
    const formdata = new FormData();
    formdata.append("file", file);
    dispatch(AddCharity(formdata,id))
      .then(() => {
        setHeadline("");
      })
      .catch((error) => {
        console.log(error);
      });
    handleCloseModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(getsingleCharity(id));
        setCharity(data.charity);
        console.log("Fetched Charity:", data.charity);
      } catch (error) {
        console.error("Error fetching charity data", error);
      }
    };
    fetchData();
  }, [dispatch, id]);

  const handletoOpenaCharity = (url) => {
    window.open(url, "_blank");
    console.log("Opening URL:", url);
  };

  return (
    <>
      <div className="allEvent">
        <Sidebar />
        <div className="events">
          <div className="eventbox" style={{ height: "100%" }}>
            {charity && charity.Images?.map((item, index) => (
              <div className="eventCards" key={item._id}>
                <div className="eventbanner" style={{ width: "7rem", height: "30px" }}>
                  <p>{`Image ${index + 1}`}</p>
                </div>
                <div className="eventlimit">
                  <button onClick={() => handletoOpenaCharity(item.image.url)}>
                    <IoEyeSharp />
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Button to open the Create Charity modal */}
          <button
            style={{ margin: "2rem", height: "3rem", borderRadius: "13px" }}
            onClick={handleOpenModal}
          >
            Create Charity
          </button>

          {/* Modal */}
          {isModalOpen && (
            <div className="modalOverlay">
              <div className="modalContent">
                <h2>Add Image</h2>

                {/* File Input */}
                <div className="modalField">
                  <label htmlFor="file">Upload File</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    accept="image/*,application/pdf"
                  />
                </div>

                {/* Buttons */}
                <div className="modalButtons">
                  <button onClick={handleCloseModal}>Close</button>
                  <button onClick={handleCreateCharity}>Create</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* CSS for Modal */}
      <style jsx>{`
        .modalOverlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modalContent {
          background-color: white;
          padding: 2rem;
          border-radius: 10px;
          max-width: 500px;
          width: 100%;
        }
        .modalField {
          margin-bottom: 1rem;
        }
        .modalField label {
          display: block;
          margin-bottom: 0.5rem;
        }
        .modalField input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .modalButtons {
          display: flex;
          justify-content: space-between;
        }
        .modalButtons button {
          padding: 0.5rem 1rem;
          border: none;
          background-color: #007bff;
          color: white;
          border-radius: 5px;
          cursor: pointer;
        }
        .modalButtons button:first-child {
          background-color: red;
        }
      `}</style>
    </>
  );
};

export default ViewCharity;
