import React, { useState } from "react";
import "../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Form, Row,Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addNominees } from "../redux/action/companyaction";
import Sidebar from "./Sidebar/Sidebar";

const FirstNomineeAdd = () => {
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [photo, setPhoto] = useState(null);
    const [aadharCard, setAadharCard] = useState(null);
    const [panCard, setPanCard] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const back = () => {
      navigate("/profile");
    };
  
    const nexthandler = () => {
      navigate(`/UploadSpvDocuments/${companyId}`);
    };
 
    const submithandler = (e) => {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      formData.append("NomineeName", name);
      formData.append("NomineeEmail", email);
      formData.append("photo", photo);
      formData.append("aadharCard", aadharCard);
      formData.append("panCard", panCard);
  
      dispatch(addNominees(formData, companyId))
        .then(() => {
          // Reset form fields after successful upload
          setName("");
          setEmail("");
          setPhoto(null);
          setAadharCard(null);
          setPanCard(null);
        })
        .catch((error) => {
          // Handle errors if needed
          console.error("Error uploading data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const handleFileChange = (setter) => (e) => {
      setter(e.target.files[0]);
    };
  const Backhandler = () => {
    navigate(`/addSpvDirectors/${companyId}`)
  }
  return (
    <div className="all">
      <Sidebar/>
      <IoMdArrowBack className="corner-back" size={27} onClick={back} />
      <button></button>
      <div className="container" style={{
        width:"70vw"
      }} >
        <h3> Add Spv Nominee</h3>
        <hr />

        <Form onSubmit={submithandler}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Profile Photo</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setPhoto)} />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Aadhar Card</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange(setAadharCard)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>PAN Card</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setPanCard)} />
          </Form.Group>
          <button className="privews" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="warning"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            Add
          </button>

          <button className="privews" onClick={nexthandler}>
            Next
          </button>
          <button className="privews" onClick={Backhandler}>
            Back
          </button>
        </Form>
      </div>
    </div>
  )
}

export default FirstNomineeAdd;


