import React, { useEffect, useState } from "react";
import "./Alltraining.css";
import axios from "axios";
import * as XLSX from "xlsx";

const GetTraining = () => {
    const [training, setTraining] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:4000/api/v1/alltraining");
                console.log("Data fetched:", response.data);
                setTraining(response.data.training);
            } catch (error) {
                console.error("Error fetching training data:", error);
            }
        };
        fetchData();
    }, []);

    const handleDownloadExcel = () => {
        // Format the data for Excel
        const dataForExcel = training.map(item => {
            // Iterate over documents and passports to list each with company name and document type
            const rows = [];

            item.document.forEach((doc, index) => {
                rows.push({
                    "Company Name": item.Company?.name || "N/A",
                    "Created At": new Date(item.createdAt).toLocaleDateString("en-IN"),
                    "Document Type": `Training Form ${index + 1}`,
                    "URL": doc.url
                });
            });

            item.passport.forEach((pass, index) => {
                rows.push({
                    "Company Name": item.Company?.name || "N/A",
                    "Created At": new Date(item.createdAt).toLocaleDateString("en-IN"),
                    "Document Type": `Passport ${index + 1}`,
                    "URL": pass.url
                });
            });

            return rows;
        }).flat();  // Flatten the array to create a single list of rows

        // Create a new worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Training Data");

        // Trigger the download
        XLSX.writeFile(workbook, "TrainingData.xlsx");
    };

    return (
        <div>
            <div className="alltraining">
                <div className="headinggg">
                    <h1>All Training</h1>
                    <button onClick={handleDownloadExcel}>Download Excel</button>
                </div>
                {training.length > 0 ? (
                    training.map((item) => (
                        <div className="getcard" key={item._id}>
                            <h4>{item.Company?.name || "Company Name"}</h4>
                            <p>Created At: {new Date(item.createdAt).toLocaleDateString("en-IN")}</p>
                            <button onClick={() => window.open(item.document[0]?.url)}>Training Form 1</button>
                            <button onClick={() => window.open(item.passport[0]?.url)}>Passport 1</button>
                            {item.document[1] && <button onClick={() => window.open(item.document[1].url)}>Training Form 2</button>}
                            {item.passport[1] && <button onClick={() => window.open(item.passport[1].url)}>Passport 2</button>}
                        </div>
                    ))
                ) : (
                    <p>No training data available.</p>
                )}
            </div>
        </div>
    );
};

export default GetTraining;
