import React, { useEffect, useState } from "react";
import "./ClientTraining.css";
import { useDispatch } from "react-redux";
import { getmytraining, mytraining } from "../../redux/action/companyaction";
import { useParams } from "react-router-dom";
import logo from "../logo-vpvv.png";

const ClientTraining = () => {
  const [training, setTraining] = useState({});
  const [gettraining, setGettraining] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the id from the URL params
  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(mytraining(id));
      setTraining(result?.training);
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(getmytraining(id));
      setGettraining(result?.training);
    };
    fetchData();
  }, [dispatch, id]);

  return (
    <>
      <div className="clienttrainingall">
        <img style={{ marginTop: "2rem" }} src={logo} alt="q" />
        <h2 style={{ color: "white" }}>TRAINING MODULE</h2>
        {training ? (
          <>
            {gettraining?.activate === "false" && (
              <div className="trainingstatus">
                <h4>
                  YOUR TRAINING MODULE HAS BEEN RELEASED AND THE PORTAL IS GOING
                  INTO HYBERNATION MODE FOR SYNCHRONIZATION.
                </h4>
                <h4>
                  YOUR TRAINING MODULE DATA WILL BE SYNCHRONIZED WITH THE
                  DATABASE AND UPDATED IN THE PORTAL SHORTLY.
                </h4>
              </div>
            )}
            {gettraining?.activate === "true" && (
              <div className="trainingstatus">
                <div
                  className="mytraining"
                  style={{ width: "100%", backgroundColor: "#7bab7b" }}
                >
                  <h4>STATUS</h4>
                  <h4>
                    {gettraining?.Status?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                </div>
              </div>
            )}
            <div className="mytrainingbox">
              <div className="mytraining">
                <h4>COMPANY NAME</h4>
                <h4>
                  {gettraining?.Companyname?.toUpperCase() ||
                    "XXXXXXXXXXXXXXXXXXX"}
                </h4>
              </div>
              <div className="mytraining">
                <h4>TRAINEE 1</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Trainee1?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                                  </h4>
                                  
                ):(<h4>XXXXXXXXXXXXXXXXXXX</h4>)}
              </div>
              <div className="mytraining">
                <h4>TRAINEE 2</h4>
                <h4>XXXXXXXXXXXXXXXXX</h4>
              </div>
              <div className="mytraining">
                <h4>APO/FPO:</h4>

                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.APO?.toUpperCase() || "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>TYPE</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Type?.toUpperCase() || "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>ZONE</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Zone?.toUpperCase() || "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>ASSIGNED UNIFORM:</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.AssignedUniform?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>TRAINER ASSIGNED:</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.TrainerAssigned?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>TRAINER NAME:</h4>
                <h4>XXXXXXXXXXXXXXXXXXXXX</h4>
              </div>
              <div className="mytraining">
                <h4>TRAINER DESIGNATION:</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.TrainerDesignation?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>COMMAND</h4>
                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Commands?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>STATUS</h4>

                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Status?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>SECTOR</h4>

                {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Sector?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
              <div className="mytraining">
                <h4>LEVEL</h4>
               
                              {gettraining?.activate === "true" ? (
                  <h4>
                    {gettraining?.Level?.toUpperCase() ||
                      "XXXXXXXXXXXXXXXXXXX"}
                  </h4>
                ) : (
                  <h4>XXXXXXXXXXXXXXXXXXX</h4>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className="notraining">
              <h4>
                YOU HAVE NOT FILED THE NSTM-I FORM AS PER THE DATABASE. PLEASE
                FILL UP THE TRAINING FORM AND UPLOAD FOR FURTHER PROCESSING.
              </h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientTraining;
