import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./Training.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer";
import logo from "../logo-vpvv.png";
import { createTraining } from "../../redux/action/companyaction";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const TrainingForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPassport, setSelectedPassport] = useState(null);
  const [isDownloaded, setIsDownloaded] = useState(false); // Track if the file has been downloaded
  const [formCount, setFormCount] = useState(0); // Track number of forms to show
  const [forms, setForms] = useState([]); // Store forms data
  const dispatch = useDispatch();
  const { id } = useParams(); // Get id from the URL params

  // Handle file change for the primary document
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Handle file change for passport upload
  const handlePassportChange = (e) => {
    setSelectedPassport(e.target.files[0]);
  };

  // Handle dynamic form count change
  const handleFormCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    if (isNaN(count) || count < 0 || count > 10) return; // Restrict between 0 and 10

    setFormCount(count);
    setForms(
      Array.from({ length: count }).map(() => ({
        trainingFile: null,
        passportFile: null,
      }))
    );
  };

  // Handle dynamic file upload changes
  const handleInputChange = (index, field, e) => {
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      [field]: e.target.files[0],
    };
    setForms(updatedForms);
  };

  // Handle file upload logic
  const handleFileUpload = async () => {
    // Check if all the forms have both trainingFile and passportFile selected
    const isFormIncomplete = forms.some(form => !form.trainingFile || !form.passportFile);
  
    if (isFormIncomplete) {
      toast.error("Please select both the training file and passport for all forms.");
      return;
    }
  
    const formData = new FormData();
    formData.append("companyId", id); // Append company ID
  
    // Append each form's trainingFile and passportFile to formData using the correct field names
    forms.forEach((form, index) => {
      formData.append(`file`, form.trainingFile);     // Use 'file' as the field name for training files
      formData.append(`passport`, form.passportFile); // Use 'passport' as the field name for passport files
    });
  
    try {
      setLoading(true);
      await dispatch(createTraining(formData, id, navigate));  // Ensure the route matches correctly
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to upload file:", error);
      toast.error("Failed to upload file. Please try again.");
    }
  };
  

  // Handle PDF download logic and enable file upload
  const handleDownloadPdf = async () => {
    const pdfUrl =
      "https://vpvvpublic.s3.ap-south-1.amazonaws.com/NSTM+FORM+I+(1).pdf";
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "NSTM-I.pdf"); // Specify the file name for download
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // After successful download, enable file upload
      setIsDownloaded(true);
    } catch (error) {
      console.error("Failed to download PDF:", error);
      alert("Failed to download file. Please try again.");
    }
  };

  return (
    <>
      <div className="refundall">
        <img src={logo} alt="logo" />
        <h2 style={{ color: "white" }}>VPVV TECHNO CONSTRUCTION PRIVATE LIMITED</h2>
        <h3 style={{ color: "white" }}>TRAINING FORM</h3>
        <div className="refuldrow">
          <div className="refundbox">
            {/* Text content */}
            Click On "Download the NSTM - I Form" to download the training form.
            Read the instructions carefully as an incorrectly filled form may lead to cancellation or a delayed process. Please attach all the relevant Document as specified along with the Form. All the Directors of the SPV has to fill out the form and upload it respectively.

            {/* Download PDF Button */}
            <div className="pdf-download-section">
              <Button variant="primary" onClick={handleDownloadPdf} className="mt-3">
                Download NSTM - I Form
              </Button>
            </div>

            {/* Select Number of Forms */}
            <Form.Group className="mb-3 mt-4" controlId="formCount">
              <Form.Label>Number of Form Submission</Form.Label>
              <Form.Control
                as="select"
                value={formCount}
                onChange={handleFormCountChange}
              >
                <option value="">Select Number of Forms</option>
                {[...Array(2).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Show message if no forms are selected */}
            {formCount === 0 && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Please select the number of forms to proceed.
              </p>
            )}

            {/* File Upload Section */}
            {formCount > 0 && (
              <>
                <div className="file-upload-section mt-4">

                  {/* Dynamic Form Fields for Multiple Directors */}
                  {forms.map((form, index) => (
                    <div key={index} className="mt-4">
                      <hr />
                      <Form.Group controlId={`trainingFile-${index}`} className="mb-3">
                        <Form.Label>Upload Training form {index + 1}</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => handleInputChange(index, "trainingFile", e)}
                        />
                      </Form.Group>

                      <Form.Group controlId={`passportFile-${index}`} className="mb-3">
                        <Form.Label>Upload Passport {index + 1}</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => handleInputChange(index, "passportFile", e)}
                        />
                        {/* Display message below each passport field */}
                        <p style={{ color: "red" }}>
                          (Ensure the passport is clearly visible for verification.)
                        </p>
                      </Form.Group>
                    </div>
                  ))}

                  <Button
                    variant="success"
                    onClick={handleFileUpload}
                    disabled={
                      loading || 
                      forms.some(form => !form.trainingFile || !form.passportFile) // Disable if any form lacks required files
                    }
                  >
                    {loading ? <Spinner animation="border" size="sm" /> : "Upload"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <hr style={{ margin: "0", backgroundColor: "#cca857", color: "#cca857", height: "0rem" }} />
      <Footer />
    </>
  );
};

export default TrainingForm;
