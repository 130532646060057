import React, { useState } from "react";
import axios from "axios"; // Ensure axios is installed and imported
import { server } from "../../redux/store";
import { useParams } from "react-router-dom";

const SpvOrder = () => {
  const [workOrderStatus, setWorkOrderStatus] = useState("");
  const [guaranteeBondStatus, setGuaranteeBondStatus] = useState("");
    const [loading, setLoading] = useState(false); // To handle loading state
    const {id} = useParams()
  const handleSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();
    try {
      const response = await axios.post(`${server}/addOrder/${id}`, {
        workOrderStatus,
        guaranteeBondStatus,
      });
        console.log("Response:", response.data);
        
    } catch (error) {
      console.error("Error submitting order:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="updateall">
      <div className="container">
        <div className="line">
          <h3>Work Order </h3>
          <div style={{ marginLeft: "2rem" }}>
            <label style={{ margin: "2rem" }}>
              <input
                type="radio"
                name="workOrder"
                value="issued"
                checked={workOrderStatus === "issued"}
                onChange={() => setWorkOrderStatus("issued")}
              />
              Issued
            </label>
            <label>
              <input
                type="radio"
                name="workOrder"
                value="pending"
                checked={workOrderStatus === "pending"}
                onChange={() => setWorkOrderStatus("pending")}
              />
              Pending
            </label>
          </div>
        </div>

        <div className="line">
          <h3>Guarantee Bond Issue </h3>
          <div style={{ marginLeft: "2rem" }}>
            <label style={{ margin: "2rem" }}>
              <input
                type="radio"
                name="guaranteeBond"
                value="issued"
                checked={guaranteeBondStatus === "issued"}
                onChange={() => setGuaranteeBondStatus("issued")}
              />
              Issued
            </label>
            <label>
              <input
                type="radio"
                name="guaranteeBond"
                value="pending"
                checked={guaranteeBondStatus === "pending"}
                onChange={() => setGuaranteeBondStatus("pending")}
              />
              Pending
            </label>
          </div>
        </div>

        <button
          className="stylebutton"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default SpvOrder;
