import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const AllIndividuals = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const { data } = await axios.get(
          "http://localhost:4000/api/v1/getallIndividuals"
        );
        setGuests(data.individual);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchGuests();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGuests = guests?.filter(
    (guest) =>
      guest.name && guest.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredGuests.map((guest) => {
        const nominees = guest.Nominee
          ? guest.Nominee.map((nominee) => nominee.NomineeName)
          : [];
        const filledNominees = [
          ...nominees,
          ...Array(5 - nominees.length).fill(""),
        ];
        return {
          name: guest.name,
          email: guest.email,
          ...filledNominees.reduce((acc, nominee, idx) => {
            acc[`Nominee ${idx + 1}`] = nominee;
            return acc;
          },{}),
        }
      })
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IndividualList");
    XLSX.writeFile(wb, "InidvidualList.xlsx");
  };

  const renderRows = () => {
    return filteredGuests.map((guest, index) => (
      <tr key={`row-${index}`}>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.name}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {guest.email}
        </td>
        {guest.Nominee &&
          guest.Nominee.map((nominee, idx) => (
            <td
              key={`nominee-${idx}`}
              style={{ border: "2px solid #ddd", padding: "8px" }}
            >
              {nominee.NomineeName}
            </td>
          ))}
        {/* Fill empty columns if there are less than 7 nominees */}
        {[...Array(5 - (guest.Nominee?.length || 0))].map((_, idx) => (
          <td
            key={`empty-nominee-${idx}`}
            style={{ border: "2px solid #ddd", padding: "8px" }}
          ></td>
        ))}
      </tr>
    ));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>NAME</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>EMAIL</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              NOMINEE 1
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              NOMINEE 2
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              NOMINEE 3
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              NOMINEE 4
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              NOMINEE 5
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredGuests?.length > 0 ? (
            renderRows()
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                No guests found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllIndividuals;
