import React, { useState } from "react";
import "../../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Col, Form, Row, Spinner } from "react-bootstrap"; // Import Spinner
import { useDispatch } from "react-redux";
import { createNDa, handlePayment } from "../../redux/action/companyaction";
import Sidebar from "../Sidebar/Sidebar";

const RegisteredNda = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const dispatch = useDispatch();

  const [company, setCompany] = useState("");
  const [repname, setRepName] = useState("");
  const [refname, setRefName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [country, setCounry] = useState("");
  const [state, setState] = useState("");
  const [date, setDate] = useState("");
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const back = () => {
    navigate("/profile");
  };

  const Backhandler = () => {
    navigate(`/AddContract/${companyId}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();

      const formData = new FormData();
      formData.append("companyName",company)
      formData.append("RepresentativeName",repname)
      formData.append("Reference",refname)
      formData.append("email",email)
      formData.append("country",country)
      formData.append("ContactNo",contact)
      formData.append("State",state)
      formData.append("Date",date)
    if (receipt) {
      formData.append("file", receipt);
    }

    setLoading(true); // Set loading to true when form submission starts

    dispatch(createNDa(formData, companyId))
      .then(() => {
        setLoading(false); // Set loading to false after submission
        navigate("/admin/profile");
      })
      .catch((error) => {
        setLoading(false); // Set loading to false if submission fails
        console.error("Failed to add payment:", error);
        alert("Failed to add payment. Please try again.");
      });
  };

  return (
    <div className="all">
      <Sidebar />
      <div className="container" style={{ width: "70vw" }}>
        <h3 style={{ color: "black" }}>Register NDA</h3>
        <hr />
        <Form onSubmit={submitHandler} encType="multipart/form-data">
          <Form.Group as={Col} controlId="formGridTypeOfContract">
            <Form.Label>Name Of Company</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter The Name Of Company (In Capitals)"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </Form.Group>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="formGridStartContractTenure">
              <Form.Label>Representative Name</Form.Label>
              <Form.Control
                              type="text"
                              placeholder="Enter the Representative Name"
                              value={repname}
                              required
                              onChange={(e)=> setRepName(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEndContractTenure">
              <Form.Label>Reference</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Reference"
                              value={refname}
                              onChange={(e)=> setRefName(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridPayment" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                required
                              value={email}
                              onChange={(e)=>setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPayment" className="mb-3">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Contact No."
                              value={contact}
                              onChange={(e)=> setContact(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridPayment" className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country"
                required
                value={country}
                onChange={(e) => setCounry(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPayment" className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                required
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridPayment" className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridReceipt" className="mb-3">
              <Form.Label>NDA</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setReceipt(e.target.files[0])}
                accept="image/*,application/pdf"
              />
            </Form.Group>
          </Row>

          <button className="privews" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Register"}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default RegisteredNda;
