import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../logo-vpvv.png";
import "./StaffList.css";
import { Link } from "react-router-dom";

const StaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStaffList = async () => {
      try {
        const { data } = await axios.get(
          "http://localhost:4000/api/v1/allStaff"
        ); // Update with your API endpoint
        console.log(data, "data");

        setStaffList(data.staff);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch staff list");
        setLoading(false);
      }
    };

    fetchStaffList();
  }, []);

  const handleFreeze = (id) => {
    // Implement freeze functionality
    console.log("Freeze staff with ID:", id);
  };

  const handleDelete = (id) => {
    // Implement delete functionality
    console.log("Delete staff with ID:", id);
  };

  const handleChangeRole = (id) => {
    // Implement change role functionality
    console.log("Change role for staff with ID:", id);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <img style={{ width: "7rem", marginTop: "2rem" }} src={logo} alt="logo" />
      <div className="heading">
        <h2>Staff List</h2>
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Profile Photo
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Email</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Team</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Aadhar</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>PAN</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>
              Passport
            </th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Role</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {staffList.map((staff) => (
            <tr key={staff.id}>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                <img
                  src={staff.profilephoto?.url}
                  alt={staff.name}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {staff.name}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {staff.email}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {staff.Type}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                <a
                  href={staff.AadharCard?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {staff.AAdharCard?.url}   
                </a>
              </td>

              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
              <a
                  href={staff.AadharCard?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {staff.PanCard?.url}   
                </a>
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
              <a
                  href={staff.AadharCard?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {staff.Passport?.url}   
                </a>
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                {staff.role}
              </td>
              <td style={{ border: "2px solid #ddd", padding: "8px" }}>
                <button
                  onClick={() => handleFreeze(staff.id)}
                  style={buttonStyle}
                >
                  Freeze
                </button>
                <button
                  onClick={() => handleDelete(staff.id)}
                  style={buttonStyle}
                >
                  Delete
                </button>
                <button
                  onClick={() => handleChangeRole(staff.id)}
                  style={buttonStyle}
                >
                  Change Role
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const buttonStyle = {
  padding: "5px 10px",
  margin: "0 5px",
  borderRadius: "5px",
  border: "none",
  backgroundColor: "#4CAF50",
  color: "white",
  cursor: "pointer",
};

export default StaffList;
