import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TrainingModule.css";
import { useNavigate } from "react-router-dom";

const TrainingModule = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Fetch companies on component mount using axios
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get("http://localhost:4000/api/v1/alltraining");
        setCompanies(response.data.training);
        console.log(response.data.training);
        
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  // Filter companies based on search term
  const filteredCompanies = companies?.filter((company) =>
    company?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Navigate to form with company id and name in state
  const handleToAddModule = (company) => {
    navigate(`/trainingmodulecreate/${company.Company._id}`, { state: { Companyname: company.Company.name } });
  };

  return (
    <div className="trainallmodule">
      <h1>Training Module</h1>
      <input
        type="text"
        placeholder="Search for a company..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="searchInput"
      />
      <ul className="companyList">
        {companies?.map((company) => (
          <li key={company._id}>
            <button
              onClick={() => handleToAddModule(company)}
              style={{ margin: "7px", borderRadius: "5px" }}
            >
              {company.Company.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrainingModule;
