import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import logo from "../logo-vpvv.png";
import "./Direcotr.css"; // Assuming styles are in this CSS file
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getmyclient, ViewDocuments } from "../../redux/action/companyaction";
import Footer from "../Footer/Footer";
import Disclaimer from "../Disclaimer/Disclaimer";

const Director = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null); // Initial state for profile
  const [image, setImage] = useState("");

  const handletoprofile = () => {
    navigate("/userDashboard");
  };

  const handletoopenview = async (key) => {
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await dispatch(getmyclient());
        setProfile(response); // Store the profile data here

        if (response.individual === "true") {
          setImage(response.IndividualDocs?.[0]?.photo || ""); // Example for individual image
        } else {
          setImage(response.companylogo?.url || ""); // Example for company logo
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfile();
  }, [dispatch]);

  return (
    <>
      <div className="frontdirector">
        <button className="to" onClick={handletoprofile}>
          <IoMdArrowBack size={27} />
        </button>
        <div className="userdirector">
        <img style={{width:"13rem"}} src={logo} alt="vpvv" />

          <div>
            <h3 className="director-text" style={{ color: "#fff" }}>
              {profile?.individual === "true" ? "Individual" : "Director"}
            </h3>

            {/* Render Individual Info */}
            {profile?.individual === "true" ? (
              <div className="directordetails">
                <div className="director-info">
                  <h4>Name: {profile.name}</h4>
                  <h4>Email: {profile.email}</h4>
                  <div className="button-group">
                    {/* Display individual documents if available */}
                    {image.url ? (
                      <button
                        className="view-btn"
                        onClick={() => handletoopenview(image.key)}
                      >
                        View Image
                      </button>
                    ) : (
                      <span style={{ marginRight: "7px" }}>Image Not available</span>
                    )}

                    {/* Aadhaar Button */}
                    {profile.IndividualDocs?.[0]?.AAdhar?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(profile.IndividualDocs[0].aadhar.key)
                        }
                      >
                       View AADHAAR
                      </button>
                    ) : (
                      <span style={{ marginRight: "7px" }}>Aadhaar Not available</span>
                    )}

                    {/* PAN Button */}
                    {profile.IndividualDocs?.[0]?.Pan?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(profile.IndividualDocs[0].pan.key)
                        }
                      >
                        View PAN
                      </button>
                    ) : (
                      <span style={{ marginRight: "7px" }}>PAN Not available</span>
                    )}

                    {/* Passport Button */}
                    {profile.IndividualDocs?.[0]?.Passport?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(profile.IndividualDocs[0].passport.key)
                        }
                      >
                        View Passport
                      </button>
                    ) : (
                      <span style={{ marginRight: "7px" }}>Passport Not available</span>
                    )}
                    {profile.IndividualDocs?.[0]?.VoterId?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(profile.IndividualDocs[0].VoterId.key)
                        }
                      >
                        View Voter Id
                      </button>
                    ) : (
                      <span style={{ marginRight: "7px" }}>Voter Id Not available</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              /* Render Director Info */
              profile?.SpvDirectors?.map((director, index) => (
                <div className="directordetails" key={director._id}>
                  {director.Directorphoto?.url ? (
                    <button
                      className="new"
                      onClick={() => handletoopenview(director.Directorphoto.key)}
                    >
                      View Image
                    </button>
                  ) : (
                    <span style={{ marginRight: "7px" }}>Image Not available</span>
                  )}
                  <div className="director-info">
                    <h4>Name: {director.DirectorName}</h4>
                    <h4>Email: {director.Directoremail}</h4>
                    <div className="button-group">
                      {director.DirectorAadhar?.url ? (
                        <button
                          className="view-btn"
                          onClick={() =>
                            handletoopenview(director.DirectorAadhar.key)
                          }
                        >
                          View AADHAAR
                        </button>
                      ) : (
                        <span>AADHAAR Not available</span>
                      )}
                      {director.DirectorPan?.url ? (
                        <button
                          className="view-btn"
                          onClick={() =>
                            handletoopenview(director.DirectorPan.key)
                          }
                        >
                          View PAN
                        </button>
                      ) : (
                        <span>PAN Not available</span>
                      )}
                      {director.DirectorPassport?.url ? (
                        <button
                          className="view-btn"
                          onClick={() =>
                            handletoopenview(director.DirectorPassport.key)
                          }
                        >
                          View Passport
                        </button>
                      ) : (
                        <span>PASSPORT Not available</span>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {/* <p style={{color:"white", back}}>The Documents or the Details which is showing as "Not Available" or the Documents which are not of High resolution, Kindly Send those Documents/Information on  WebSupportT2@vpvvindia.com</p> */}
      <Disclaimer/>
      <Footer  />
    </>
  );
};

export default Director;
