import React, { useState } from "react";
import "../../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addindividualdocs } from "../../redux/action/companyaction";
import Sidebar from "../Sidebar/Sidebar";

const IndividualAddDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [photo, setPhoto] = useState(null);
  const [aadharCard, setAadharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [passport, setPassport] = useState(null); // State for Passport
  const [voterId, setVoterId] = useState(null); // State for Passport
  const [loading, setLoading] = useState(false);

  const back = () => {
    navigate("/profile");
  };

  const nexthandler = () => {
    navigate(`/IndividualAddNomineeAdd/${id}`);
  };

  const backHandler = () => {
    navigate(`/admin/addspvv/${id}`);
  };

  const submithandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("aadharCard", aadharCard);
    formData.append("panCard", panCard);
    formData.append("passport", passport); // Append Passport field
    formData.append("Voter", voterId); // Append Passport field

    dispatch(addindividualdocs(formData, id))
      .then(() => {
        setPhoto(null);
        setAadharCard(null);
        setPanCard(null);
        setPassport(null); 
        setVoterId(null)// Reset Passport field
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error uploading data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (setter) => (e) => {
    setter(e.target.files[0]);
  };

  return (
    <div className="all">
      <Sidebar />
      <div className="container" style={{ width: "70vw" }}>
        <h3>Individual Documents</h3>
        <hr />

        <Form onSubmit={submithandler}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Profile Photo</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setPhoto)} />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Aadhar Card</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange(setAadharCard)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>PAN Card</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setPanCard)} />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Passport</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange(setPassport)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Voter Id</Form.Label>
            <Form.Control type="file" onChange={handleFileChange(setVoterId)} />
          </Form.Group>
          <button className="privews" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="warning"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            Add
          </button>

          <button className="privews" onClick={nexthandler}>
            Next
          </button>
          <button className="privews" onClick={backHandler}>
            Back
          </button>
        </Form>
      </div>
    </div>
  );
};

export default IndividualAddDocument;
