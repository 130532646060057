import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Training.css";
import Footer from "../Footer/Footer";
import logo from "../logo-vpvv.png";

const Agreement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox status

  // Function to navigate to the Training Form
  const handleToSubmit = () => {
    navigate(`/TrainingForm/${id}`);
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      <div className="agreeall">
        <img src={logo} alt="logo" />
        <h2 style={{ color: "white" }}>
          VPVV TECHNO CONSTRUCTION PRIVATE LIMITED
        </h2>
        <div className="refuldrow">
          <h3 style={{ color: "red" }}>
            Please Read The Instructions Carefully Before Proceeding.
          </h3>

          <div className="agreement-container">
            {/* Checkbox to agree */}
            <div className="agreement">
              <p>
                By completing this form, you agree to provide information that
                is accurate, truthful, and complete to the best of your
                knowledge. The details you provide will be used to assess your
                suitability and compliance with our requirements. It is crucial
                that all information entered is thoroughly reviewed before
                submission. You are solely responsible for ensuring that every
                response is correct and reflects your actual circumstances.
              </p>
              <p>
                Please be aware that this form allows for only one-time
                submission. Once you have submitted the form, no changes can be
                made, and no additional opportunities will be provided to
                correct any errors. It is your responsibility to double-check
                all entries and confirm their accuracy before clicking the
                submit button. Failing to do so could result in significant
                negative outcomes.
              </p>
              <p>
                Inaccurate or incomplete submissions, even if unintentional, can
                also lead to substantial delays in processing and approval. This
                can affect timelines and may lead to a loss of trust or further
                scrutiny in any future interactions or agreements. Therefore, it
                is essential that you approach this form with the utmost
                diligence and care
              </p>
              <p>
                By submitting this form, you confirm that you have read and
                understood the terms outlined above. You acknowledge that you
                have taken the necessary steps to ensure all provided
                information is accurate and complete. Your submission serves as
                your formal agreement to comply with these conditions and accept
                the responsibilities and potential consequences that come with
                any deviation from them.
              </p>
            </div>

            {/* Submit Button (Disabled if checkbox is not checked) */}
            {/* <label htmlFor="">
              <input type="checkbox" onClick={handleCheckboxChange} />
              <p style={{color:"white"}}>I have read the instructions thoroughly and take full responsibility of the information in the form i will fill.</p>
              </label> */}
            <div className="check" >
              <input type="checkbox" onClick={handleCheckboxChange} />
              <p style={{color:"white", margin:0}}>I have read the instructions thoroughly and take full responsibility for the information in the form I will fill.</p>
            </div>

              <button
                className="AgreeButton"
                onClick={handleToSubmit}
                disabled={!isChecked} // Disable button if checkbox is not checked
                style={{
                  cursor: isChecked ? "pointer" : "not-allowed", // Change cursor based on button state
                  backgroundColor: isChecked ? "#fff" : "#6c757d", // Change color based on state
                  color: isChecked ? "black" : "6c757d", // Change color based on state
                }}
              >
                Continue
              </button>
          </div>
        </div>
      </div>
      <hr
        style={{
          margin: "0",
          backgroundColor: "#cca857",
          color: "#cca857",
          height: "0rem",
        }}
      />
      <Footer />
    </>
  );
};

export default Agreement;
