import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./ClientEvent.css";
import logo from "../logo-vpvv.png";
import { IoEyeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { TfiWrite } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { deleteEvent, getallEvents, CreateEvent, updateEvent } from "../../redux/action/companyaction";
import Modal from "./ShowModal";
import EventDetailsModal from "./EventDetailModal";
import UpdateEventModal from "./UpdateEventModal";
import { useNavigate } from "react-router-dom";

const Event = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await dispatch(getallEvents());
      setEvents(response);
    };
    fetchEvents();
  }, [dispatch]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  
  const handleOpenDetailsModal = (event) => {
    setSelectedEvent(event);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedEvent(null);
  };

  const handleOpenUpdateModal = (event) => {
    setSelectedEvent(event);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedEvent(null);
  };

  const deleteHandler = async (id) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    await dispatch(deleteEvent(id));
    setLoading((prev) => ({ ...prev, [id]: false }));
    setEvents((prevEvents) => prevEvents.filter((event) => event._id !== id));
  };

  const handletoguest = (id) => {    
    navigate(`/admin/guest/${id}`);
  }

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    setModalLoading(true);
  
    try {
      await dispatch(CreateEvent(formData));  // Dispatching the FormData directly
      const response = await dispatch(getallEvents());
      setEvents(response);
    } catch (error) {
      console.error("Event creation failed:", error);
    } finally {
      setModalLoading(false);
      setShowModal(false);
    }
  };

  const handleUpdate = async (updatedData) => {
    setModalLoading(true);
    try {
      await dispatch(updateEvent({ id: selectedEvent._id, ...updatedData }));
      const response = await dispatch(getallEvents());
      setEvents(response);
    } catch (error) {
      console.error("Event update failed:", error);
    } finally {
      setModalLoading(false);
      setShowUpdateModal(false);
      setSelectedEvent(null);
    }
  };

  return (
    <div className="allEvent">
      <Sidebar />
      <div className="events">
        <div className="eventbox">
          {events && events.length > 0 ? (
            events.map((event, index) => (
              <div className="eventCards" key={index}>
                <div className="eventbanner">
                  <img src={event.banner.url || logo} alt="eventbanner" />
                </div>
                <div className="eventdetails">
                  <p>Event Date: {event.date.split("T")[0]}</p>
                  <p>Event Name: {event.title}</p>
                </div>
                <div className="eventlimit">
                  <button style={{ width: "2rem" }} onClick={() => handleOpenUpdateModal(event)}>
                    <TfiWrite />
                  </button>
                  <button onClick={() => handleOpenDetailsModal(event)}>
                    <IoEyeSharp />
                    View
                  </button>
                  <button style={{ width: "10em" }} onClick={() => handletoguest(event._id)}>
                    <IoEyeSharp />
                    Confirmed RSVP
                  </button>
                  <button
                    style={{ width: "2rem" }}
                    onClick={() => deleteHandler(event._id)}
                    disabled={loading[event._id]}
                  >
                    {loading[event._id] ? (
                      <span className="spinner"></span>
                    ) : (
                      <MdDelete size={20} />
                    )}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No events found.</p>
          )}
        </div>
        <div className="createeventbutton">
          <button onClick={handleOpenModal}>Create Event</button>
          <Modal
            show={showModal}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            loading={modalLoading}
          />
        </div>
      </div>
      {selectedEvent && (
        <>
          <EventDetailsModal
            show={showDetailsModal}
            onClose={handleCloseDetailsModal}
            event={selectedEvent}
          />
          <UpdateEventModal
            show={showUpdateModal}
            onClose={handleCloseUpdateModal}
            onSubmit={handleUpdate}
            event={selectedEvent}
            loading={modalLoading}
          />
        </>
      )}
    </div>
  );
};

export default Event;
