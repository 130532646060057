import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import logo from "../logo-vpvv.png";
import "../UserDirector/Direcotr.css";
import { useNavigate } from 'react-router-dom';

const SpvDirectors = () => {

  const navigate = useNavigate()
  const handletoprofile = () => {
    navigate("/userDashboard")
  };

  return (
    <>
      <div className="frontdirector">
      <button className="to" onClick={handletoprofile}>
          <IoMdArrowBack size={27} />
        </button>
        <div className="userdirector">
          <h3 className="director-text" style={{color:"white"}}>Nominees:</h3>
          <div className="directordetails">
            <img src={logo} alt="Logo" className="director-logo" />
            <div className="director-info">
              <h4>Name: Harsh</h4>
              <h4>Email: harsh@example.com</h4>
              <div className="button-group">
                <button className="view-btn">View AADHAR</button>
                <button className="view-btn">View PAN</button>
              </div>
            </div>
          </div>
          <hr/>
        </div>
      </div>
    </>
  );
}

export default SpvDirectors;

