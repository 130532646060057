import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import logo from "../logo-vpvv.png";
import "./Loginhistory.css";

const LoginHistory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loginHistory, setLoginHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLoginHistory = async () => {
      try {
        const { data } = await axios.get("http://localhost:4000/api/v1/getallloginhistories");
        setLoginHistory(data.histories);
        console.log("data", data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch login history");
        setLoading(false);
      }
    };

    fetchLoginHistory();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredHistory = loginHistory.filter(
    (entry) =>
      entry.name &&
      entry.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredHistory.map((entry) => ({
        "User Name": entry.name,
        Timing: entry.loginTime,
        "Login Place": `${entry.location.city}, ${entry.location.region}, ${entry.location.country}`,
        Device: `${entry.device.deviceName}, ${entry.device.browser}, ${entry.device.os}`,
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LoginHistory");
    XLSX.writeFile(wb, "LoginHistoryData.xlsx");
  };

  const renderRows = () => {
    return filteredHistory.map((entry, index) => (
      <tr key={`row-${index}`}>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.name}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>{entry.loginTime}</td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {entry.location.city}, {entry.location.region}, {entry.location.country}
        </td>
        <td style={{ border: "2px solid #ddd", padding: "8px" }}>
          {entry.device.deviceName} ({entry.device.deviceType}) - {entry.device.browser} on {entry.device.os}
        </td>
      </tr>
    ));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ padding: "20px" }}>
      <img style={{ width: "7rem", marginTop: "2rem" }} src={logo} alt="logo" />
      <div className="heading">
        <h2>Employee Login History</h2>
      </div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search by user name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            padding: "10px",
            width: "calc(100% - 120px)",
            boxSizing: "border-box",
            borderRadius: "13px",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            borderRadius: "13px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>User Name</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Timing</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Login Place</th>
            <th style={{ border: "2px solid #ddd", padding: "8px" }}>Device</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default LoginHistory;
