import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "./logo-vpvv.png";
import { createlogactivity, getmyclient } from "../redux/action/companyaction";
import "./Header.css";
import { IoMdLogOut } from "react-icons/io";

const Header = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [nominee, setNominee] = useState({});
  const [src, setSrc] = useState("");
  const navigate = useNavigate();

  const action = "get";

  const handletodirector = () => {
    navigate("/user/director", {
      state: { directors: profile?.SpvDirectors || [] },
    });
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the Directors `
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to see the Directors `
          )
        );
  };
  const handletospvDirector = () => {
    navigate("/user/Spvdirector");
  };
  const handletospvNominee = () => {
    navigate("/user/Spvnominee");
  };
  const handletoSpvDocuments = () => {
    navigate("/user/SpvDocuments", {
      state: { spvDocs: profile?.spvvdocs || [] },
    });
    dispatch(
      createlogactivity(
        profile?._id,
        null,
        action,
        `${profile?.name} is trying to  see the spv Documents`
      )
    );
  };

  const handletonominee = () => {
    navigate("/user/nominee", { state: { nominees: nominee || {} } });
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the individual nominees`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to access the nominees`
          )
        );
  };
  const handletoEvents = () => {
    navigate(`/Events`, { state: { companyid: profile?._id } });
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to visit the Assembly`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to visit the Assembly`
          )
        );
  };

  const handletohelp = () => {
    navigate("/helpdesk");
  };
  const handletoDashboard = () => {
    navigate("/userDashboard");
  };
  const handletoContract = () => {
    profile?.individual === "true"
      ? navigate("/user/temp", { state: { Contract: profile?.Contract || [] } })
      : navigate("/user/Contract", {
          state: { Contract: profile?.Contract || [] },
        });

    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the Contract`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to access see the Contract`
          )
        );
  };
  const handletodeposit = () => {
    navigate("/user/temp");
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the Deposit Emd`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to  see the Deposit Emd`
          )
        );
  };
  const handletologout = () => {
    navigate("/");
  };
  const handletocharity = () => {
    navigate("/vpvvcharity");
  };
  const handletoopenaccount = (id) => {
    if (profile?.individual === "true") {
      navigate(`/CorporatBankAccount/${id}`, {
        state: { Bankname: profile?.Bankname || "" },
      });
    } else {
      navigate(`/GlobalCorporateBankAccount/${id}`, {
        state: { Bankname: profile?.Bankname || "" },
      });
    }

    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is went to see the Bank Account Opening Form`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is went to see the Bank Acoount Opening Form`
          )
        );
  };
  const handletomytraining = (id) => {
    navigate(`/mytraining/${id}`);
  };
  const handletoopenTrainig = (id) => {
    navigate(`/agreement/${id}`);
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see Training Form`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to see Training Form`
          )
        );
  };
  const handletoEducation = (id) => {
    navigate(`/Education/${id}`);
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the Education Form`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to  see the Education Form`
          )
        );
  };
  const handletoEMD = (id) => {
    navigate(`/Refund/${id}`);
    profile?.individual === true
      ? dispatch(
          createlogactivity(
            null,
            profile?._id,
            action,
            `${profile?.name} is trying to  see the Emd Return Form`
          )
        )
      : dispatch(
          createlogactivity(
            profile?._id,
            null,
            action,
            `${profile?.name} is trying to  see the Emd Return Form`
          )
        );
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await dispatch(getmyclient());
        setProfile(response);
        console.log("Res", response);

        if (response?.individual === "true") {
          setNominee(response?.Nominee || {});
        } else {
          setNominee(response?.Nominee || {});
        }

        if (!response?.companylogo?.url) {
          setSrc(logo);
        } else {
          setSrc(response.companylogo.url);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfile();
  }, [dispatch]);

  return (
    <div className="fis" style={{ backgroundColor: "#051E3F" }}>
      <Navbar
        expand="lg"
        style={{ backgroundColor: "#051E3F" }}
        className="headerall"
      >
        <Container fluid>
          <Navbar.Brand>
            <img
              style={{
                width: "2.5rem",
                height: "2.5rem",
                borderRadius: "50%",
              }}
              src={profile?.individual === "true" ? logo : src}
              alt="Company Logo"
              className="circular-image"
            />{" "}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                onClick={handletoDashboard}
                style={{ cursor: "pointer", color: "#cca895" }}
              >
                Dashboard
              </Nav.Link>
              {/* Personal Details Dropdown */}
              <NavDropdown
                title={
                  <span style={{ color: "#cca895" }}>Personal Details</span>
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item onClick={handletodirector}>
                  {profile?.individual === "true" ? "Individual" : "Director"}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handletonominee}>
                  Nominees
                </NavDropdown.Item>
              </NavDropdown>
              {/* SPV Dropdown */}
              {profile?.individual === "true" ? (
                <NavDropdown
                  title={
                    <span style={{ color: "#cca895" }}>Financial Details</span>
                  }
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item
                    onClick={handletoContract}
                    style={{ color: "#cca895" }}
                  >
                    Finance
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={handletodeposit}
                    style={{ color: "#cca895" }}
                  >
                    Deposit
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavDropdown
                  title={<span style={{ color: "#cca895" }}>SPV</span>}
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item onClick={handletoSpvDocuments}>
                    SPV Documents
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handletoContract}>
                    Contract
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handletodeposit}>
                    Deposit
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {/* //trainingmodule */}
              {profile?.trainingmodule === "true" && (
                <Nav.Link
                  onClick={() => handletomytraining(profile?._id)}
                  style={{ color: "#cca895" }}
                >
                  My Training
                </Nav.Link>
              )}

              <Nav.Link
                onClick={() => handletoopenaccount(profile?._id)}
                style={{ color: "#cca895" }}
              >
                Global Corporate Bank
              </Nav.Link>
              <Nav.Link
                onClick={() => handletoopenTrainig(profile?._id)}
                style={{ color: "#cca895" }}
              >
                Training Investiture
              </Nav.Link>
              <Nav.Link
                onClick={() => handletoEducation(profile?._id)}
                style={{ color: "#cca895" }}
              >
                Education
              </Nav.Link>
              <Nav.Link
                onClick={() => handletoEMD(profile?._id)}
                style={{ color: "#cca895" }}
              >
                EMD
              </Nav.Link>
              <Nav.Link onClick={handletoEvents} style={{ color: "#cca895" }}>
                Assembly
              </Nav.Link>
              {/* <Nav.Link onClick={handletocharity} style={{ color: "#cca895" }}>
                For Universe
              </Nav.Link> */}
              <Nav.Link onClick={handletohelp} style={{ color: "#cca895" }}>
                Help
              </Nav.Link>
            </Nav>
            {/* <button onClick={handletologout} style={{margin:"9px" , borderRadius:"13px", padding:"2px", color:"#cca895", backgroundColor:"#051E3F"}}>
              Logout
            <IoMdLogOut />
            </button> */}
            <Navbar.Brand>
              <img
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "50%",
                }}
                src={logo}
                alt="Company Logo"
                className="circular-image"
              />{" "}
            </Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr style={{ backgroundColor: "#fff", margin: "0" }} />
    </div>
  );
};

export default Header;
