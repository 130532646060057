import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import image from "../logo-vpvv.png";
import "../UserDirector/Direcotr.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ViewDocuments } from "../../redux/action/companyaction";
import Footer from "../Footer/Footer";
import Disclaimer from "../Disclaimer/Disclaimer";

const Nominees = () => {
  const location = useLocation();
  const { nominees } = location.state || [];
  

  const navigate = useNavigate();
  const handletoprofile = () => {
    navigate("/userDashboard");
  };
  const dispatch = useDispatch();

  const handletoopenview = async (key) => {
    try {
      const documentUrl = await dispatch(ViewDocuments(key));
      window.open(documentUrl, "_blank");
      console.log("key", key);
    } catch (error) {
      console.log(error);
    }
  };

  const isButtonDisabled = (key) => !key || key === "";

  return (
    <>
      <div className="frontdirector">
        <button className="to" onClick={handletoprofile}>
          <IoMdArrowBack size={27} />
        </button>
        <div className="userdirector">
          <img style={{width:"13rem"}} src={image} alt="" />
          <h3 className="director-text" style={{ color: "white" }}>
            Nominees:
          </h3>
          {nominees.map((item, index) => (
            <div className="directordetails" key={item._id}>
              
               {item.Nomineephoto?.url ? (
                      <button
                        className="new"
                        onClick={() =>
                          handletoopenview(item.Nomineephoto.key)
                        }
                        style={{
                          margin: "10px",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        View Image
                      </button>
                    ) : (
                      <span style={{marginRight:"7px"}}>Image Not available</span>
                    )}
              <div className="director-info">
                <h4>Name: {item.NomineeName}</h4>
                <h4>Email: {item.NomineeEmail}</h4>
                <div className="button-group">
                  
                  {item.NomineeAAdhar?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(item.NomineeAAdhar.key)
                        }
                      >
                       View AADHAAR
                      </button>
                    ) : (
                      <span>AADHAAR Not available </span>
                    )}
                 
                  {item.NomineePan?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(item.NomineePan.key)
                        }
                      >
                       View PAN
                      </button>
                    ) : (
                      <span>PAN Not available </span>
                    )}
                  {item.NomineePassport?.url ? (
                      <button
                        className="view-btn"
                        onClick={() =>
                          handletoopenview(item.NomineePassport.key)
                        }
                      >
                       View Passport
                      </button>
                    ) : (
                      <span>Passport Not available </span>
                    )}
                  
                </div>
              </div>
            </div>
          ))}
        </div>
        <Disclaimer/>
      </div>
      <Footer/>
    </>
  );
};

export default Nominees;
