import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { IoEyeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { createCharity, getallCharity } from "../../redux/action/companyaction";
import { useNavigate } from "react-router-dom";

const CreateCharity = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [headline, setHeadline] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
const [charity ,setCharity] = useState([])
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleCreateCharity = () => {
    // Handle form submission logic, such as uploading file and submitting headline

    const formdata = new FormData();
    formdata.append("Heading", headline);
    formdata.append("file", file);
    dispatch(createCharity(formdata))
      .then(() => {
        setHeadline("");
      })
      .catch((error) => {
        console.log(error);
      });

    // After successful creation, you can close the modal
    handleCloseModal();
  };
  useEffect(() => {
    const fetchdata = async () => {
        try {
            const data = await dispatch(getallCharity()); // Call the action creator function
            setCharity(data.charity)
        } catch (error) {
            console.error("Error fetching charity data", error);
        }
    };
    
    fetchdata();
  }, [dispatch]);
    const navigate = useNavigate()

    const handletoOpenaCharity = (id) => {
        navigate(`/admin/charites/${id}`)
    }
  return (
    <>
      <div className="allEvent">
        <Sidebar />
        <div className="events">
          <div className="eventbox" style={{ height: "100%" }}>
                      {charity && charity?.map((item) => (
                <div className="eventCards" key={item._id}>
                <div
                  className="eventbanner"
                  style={{ width: "7rem", height: "30px" }}
                >
                  <p>{item.Headline}</p>
                </div>
                <div className="eventlimit">
                  <button onClick={() => handletoOpenaCharity(item._id)}>
                    <IoEyeSharp />
                    View
                  </button>
                </div>
              </div>
          ))}
          </div>

          {/* Button to open the Create Charity modal */}
          <button
            style={{ margin: "2rem", height: "3rem", borderRadius: "13px" }}
            onClick={handleOpenModal}
          >
            Create Charity
          </button>

          {/* Modal */}
          {isModalOpen && (
            <div className="modalOverlay">
              <div className="modalContent">
                <h2>Create Charity</h2>

                {/* Headline Input */}
                <div className="modalField">
                  <label htmlFor="headline">Headline</label>
                  <input
                    type="text"
                    id="headline"
                    value={headline}
                    onChange={(e) => setHeadline(e.target.value)}
                    placeholder="Enter charity headline"
                  />
                </div>

                {/* File Input */}
                <div className="modalField">
                  <label htmlFor="file">Upload File</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    accept="image/*,application/pdf"
                  />
                </div>

                {/* Buttons */}
                <div className="modalButtons">
                  <button onClick={handleCloseModal}>Close</button>
                  <button onClick={handleCreateCharity}>Create</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* CSS for Modal (can be moved to a CSS file) */}
      <style jsx>{`
        .modalOverlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modalContent {
          background-color: white;
          padding: 2rem;
          border-radius: 10px;
          max-width: 500px;
          width: 100%;
        }
        .modalField {
          margin-bottom: 1rem;
        }
        .modalField label {
          display: block;
          margin-bottom: 0.5rem;
        }
        .modalField input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .modalButtons {
          display: flex;
          justify-content: space-between;
        }
        .modalButtons button {
          padding: 0.5rem 1rem;
          border: none;
          background-color: #007bff;
          color: white;
          border-radius: 5px;
          cursor: pointer;
        }
        .modalButtons button:first-child {
          background-color: red;
        }
      `}</style>
    </>
  );
};

export default CreateCharity;
