import React, { useState } from "react";
import "../Styles/Register.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Form, Row,Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { uploadDocuments } from "../redux/action/companyaction";
import Sidebar from "./Sidebar/Sidebar";

const UploadDocuments = () => {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const [certificate, setCertificate] = useState(null);
    const [compamyPan, setCompanyPan] = useState(null);
    const [companyTan, setCompanyTan] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const back = () => {
      navigate("/profile");
    };
  
    const nexthandler = () => {
      navigate(`/AddContract/${companyId}`);
    };
 
    const submithandler = (e) => {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      formData.append("certificate", certificate);
      formData.append("companyPan", compamyPan);
      formData.append("companyTan", companyTan);
  
      dispatch(uploadDocuments(formData, companyId))
        .then(() => {
          // Reset form fields after successful upload
          setCertificate(null);
          setCompanyPan(null);
          setCompanyTan(null);
        })
        .catch((error) => {
          // Handle errors if needed
          console.error("Error uploading data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const handleFileChange = (setter) => (e) => {
      setter(e.target.files[0]);
    };
  const Backhandler = () => {
    navigate(`/UploadSpvDocuments/${companyId}`)
  }
  return (
    <div className="all">
      <Sidebar />
      
      <div className="container" style={{width:"70vw"}}>
        <h3> Add Documents (parent Company)  </h3>
        <hr />

        <Form onSubmit={submithandler}>
        <Form.Group controlId="formFile1" className="mb-3">
        <Form.Label>Incorporation Certificate</Form.Label>
        <Form.Control type="file" onChange={handleFileChange(setCertificate)}  />
      </Form.Group>
      <Form.Group controlId="formFile2" className="mb-3">
        <Form.Label>Company PAN</Form.Label>
        <Form.Control type="file"  onChange={handleFileChange(setCompanyPan)}  />
      </Form.Group>
      <Form.Group controlId="formFile2" className="mb-3">
        <Form.Label>Company TAN</Form.Label>
        <Form.Control type="file"  onChange={handleFileChange(setCompanyTan)} />
      </Form.Group>
      <button className="privews" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="warning"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            Add
          </button>
      <button className="privews" onClick={nexthandler}>
        Next
          </button>
          <button className="privews" onClick={Backhandler}>
        Back
      </button>
        </Form>
      </div>
    </div>
  )
}

export default UploadDocuments


