import React, { useState } from "react";
import "./EmdReturned.css";
import logo from "../logo-vpvv.png";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux"; // Assuming you're using Redux
// import your action (replace with the actual action file)

const EmdReturned = () => {
  const dispatch = useDispatch();

  // State for form fields
  const [payment, setPayment] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [refNo, setRefNo] = useState("");

  // Handle form submission
  const submitHandler = (e) => {
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();
    formData.append("payment", payment);
    formData.append("modeOfPayment", modeOfPayment);
    formData.append("paymentDate", paymentDate);
    formData.append("refNo", refNo);

    // Dispatch the action to send formData
   
  };

  return (
    <>
      <div className="emdall">
        <img src={logo} alt="vpvv" />
        <h3 style={{ color: "white" }}>Emd Returned</h3>
        <div className="emdmain">
          <Form onSubmit={submitHandler} encType="multipart/form-data">
            <Form.Group controlId="formGridPayment" className="mb-3">
              <Form.Label>Payment</Form.Label>
              <Form.Control
                type="number"
                placeholder="Amount"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formGridReceived" className="mb-3">
              <Form.Label>Mode Of Payment</Form.Label>
              <Form.Control
                as="select"
                value={modeOfPayment}
                onChange={(e) => setModeOfPayment(e.target.value)}
                required
              >
                <option>Select</option>
                <option>VIA CHEQUE</option>
                <option>VIA RTGS</option>
                <option>VIA NEFT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formGridDate" className="mb-3">
              <Form.Label>Payment Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Select Date"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formGridRefNo" className="mb-3">
              <Form.Label>Ref No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Reference Number"
                value={refNo}
                onChange={(e) => setRefNo(e.target.value)}
                required
              />
            </Form.Group>

            <button className="privews" type="submit">
              Add
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EmdReturned;
