import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import logo from "../logo-vpvv.png";
import "./Bankform.css"; // Ensure this file is set up for custom styles if needed
import Header from "../Header";
import { useDispatch } from "react-redux";
import { creatBAnkaccount } from "../../redux/action/companyaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const BankForm = () => {
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState(false);
  const [numSignatories, setNumSignatories] = useState(1);
  const [numNominees, setNumNominees] = useState(1);

  const countries = [
    "---Select---",
    "Afghanistan",
    "Algeria",
    "Andorra",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bangladesh",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chile",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const countryCodes = [
    "---select---",
    "+1",
    "+20",
    "+27",
    "+30",
    "+31",
    "+32",
    "+33",
    "+34",
    "+36",
    "+39",
    "+41",
    "+43",
    "+44",
    "+45",
    "+46",
    "+47",
    "+48",
    "+49",
    "+50",
    "+51",
    "+52",
    "+53",
    "+54",
    "+55",
    "+56",
    "+57",
    "+60",
    "+61",
    "+62",
    "+63",
    "+64",
    "+65",
    "+66",
    "+70",
    "+71",
    "+72",
    "+73",
    "+74",
    "+75",
    "+76",
    "+77",
    "+78",
    "+79",
    "+80",
    "+81",
    "+82",
    "+84",
    "+85",
    "+87",
    "+88",
    "+89",
    "+90",
    "+91",
    "+94",
    "+95",
    "+960",
    "+961",
    "+962", // Lebanon, Jordan
    "+965",
    "+966",
    "+967",
    "+968", // Kuwait, Saudi Arabia, Yemen, Oman
    "+970",
    "+971",
    "+972",
    "+973",
    "+974",
    "+975",
    "+976",
    "+977", // Palestine, UAE, Israel, Bahrain, Qatar, Bhutan, Mongolia, Nepal
    "+993",
    "+994",
    "+995",
    "+996",
    "+997",
    "+998",
    "+999",
    "+237",
    "+241",
    "+240",
    "+290",
    "+235",
    "+227",
    "+223",
    "+229",
    "+228",
  ];
  const handlePoliticallyExposedChange = (e) => {
    setIsPoliticallyExposed(e.target.value === "Yes");
  };

  const handleSignatoryCountChange = (e) => {
    const count = parseInt(e.target.value);
    setNumSignatories(count);

    // Ensure the signatories array has the right number of entries
    setSignatories((prevSignatories) => {
      const newSignatories = [...prevSignatories];
      while (newSignatories.length < count) {
        newSignatories.push({ fullName: "", email: "", contactNumber: "" });
      }
      return newSignatories.slice(0, count); // Trim the array to the selected count
    });
  };
  const location = useLocation();

  const handleNomineeCountChange = (e) => {
    const count = parseInt(e.target.value);
    setNumNominees(count);

    // Ensure the nominees array has the right number of entries
    setNominees((prevNominees) => {
      const newNominees = [...prevNominees];
      while (newNominees.length < count) {
        newNominees.push({ fullName: "", email: "", contactNumber: "" });
      }
      return newNominees.slice(0, count); // Trim the array to the selected count
    });
  };

  const [date, setDate] = useState("");
  const [nameOfOrganisation, setNameOfOrganisation] = useState("");
  const [typeOfOrganisation, setTypeOfOrganisation] = useState("");
  const [natureOfBusiness, setNatureOfBusiness] = useState("");
  const [dateOfEstablishment, setDateOfEstablishment] = useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [registeredOfficeAddress, setRegisteredOfficeAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingZipCode, setMailingZipCode] = useState("");
  const [mailingCountry, setMailingCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [politicallyExposed, setPoliticallyExposed] = useState("");
  const [position, setPosition] = useState("");
  const [affidavit, setAffidavit] = useState(null);
  const [signatories, setSignatories] = useState([
    { fullName: "", email: "", contactNumber: "", passport: "", pan: "" },
  ]);
  const [nominees, setNominees] = useState([
    { fullName: "", email: "", contactNumber: "", passport: "", pan: "" },
  ]);
  const [loading, setLoading] = useState(false); // Loading state
  const { id } = useParams();
  const dispatch = useDispatch();

  const [accountType, setAccountType] = useState("");
  const [currency, setCurrency] = useState("");
  const [initialDepositAmount, setInitialDepositAmount] = useState("");
  const [internetBanking, setInternetBanking] = useState(false);
  const [debitCard, setDebitCard] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // State for file uploads
  const [incorporationCertificate, setIncorporationCertificate] =
    useState(null);
  const [boardResolution, setBoardResolution] = useState(null);
  const [companyPan, setCompanyPan] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [authorizedSignatoryIdProof, setAuthorizedSignatoryIdProof] =
    useState(null);
  const [shareholdingPattern, setShareholdingPattern] = useState(null);
  const [moa, setMoa] = useState(null);
  const [aoa, setAoa] = useState(null);
  const [companyTan, setCompanyTan] = useState(null);
  const [bankname, setBankName] = useState("");
  const navigate = useNavigate();

  const submithandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append basic details
    formData.append("Date", date);
    formData.append("NameOfOrganisation", nameOfOrganisation);
    formData.append("TypeOFOrganisation", typeOfOrganisation);
    formData.append("NatureOfBusiness", natureOfBusiness);
    formData.append("DateOFEstablishment", dateOfEstablishment);
    formData.append("ContryofInCorporation", countryOfIncorporation);
    formData.append("Registrationumber", registrationNumber);
    formData.append("TaxNumber", taxIdentificationNumber);
    formData.append("PanNumber", panNumber);

    // Append registered office address details
    formData.append("RegisterOfficeAddress", registeredOfficeAddress);
    formData.append("RegisterOfficeCity", city);
    formData.append("RegsterZipCode", zipCode);
    formData.append("RegisterCountry", country);

    // Append mailing address details
    formData.append("MailingOfficeAddress", mailingAddress);
    formData.append("MailingOfficeCity", mailingCity);
    formData.append("MailingZipCode", mailingZipCode);
    formData.append("MailingCountry", mailingCountry);

    // Append contact information
    formData.append("Website", website);
    formData.append("OfficailEmail", officialEmail);
    formData.append("ContactNumber", contactNumber);
    formData.append("PositionPolitical", politicallyExposed);

    // Append account preferences
    formData.append("AccountType", accountType);
    formData.append("Currency", currency);
    formData.append("TermandCondition", termsAccepted);
    formData.append("InternetBanking", internetBanking);
    formData.append("DebitCard", debitCard);

    // Append authorized signatories (assuming you have validation in place)
    for (let i = 0; i < signatories.length; i++) {
      formData.append("AuthorizedFullNAme[]", signatories[i]?.fullName);
      formData.append("Authorizedemail[]", signatories[i]?.email);
      formData.append(
        "AuthorizedContactNumber[]",
        signatories[i].contactNumber
      );
      formData.append("AuthorizedPassportNo[]", signatories[i].passport);
      formData.append("AuthorizedPanNo[]", signatories[i].pan);
    }

    // Append nominees (assuming you have validation in place)
    for (let i = 0; i < nominees.length; i++) {
      formData.append("NomineeFullNAme[]", nominees[i]?.fullName);
      formData.append("Nomineeemail[]", nominees[i]?.email);
      formData.append("NomineeContactNumber[]", nominees[i].contactNumber);
      formData.append("NomineePassportNo[]", nominees[i].passport);
      formData.append("NomineePanNo[]", nominees[i].pan);
    }

    // Append file uploads
    formData.append("Incorporation", incorporationCertificate);
    formData.append("BoardResolution", boardResolution);
    formData.append("Companypan", companyPan);
    formData.append("Addressproof", addressProof);
    formData.append("AuthorizedSignatoryIdProof", authorizedSignatoryIdProof);
    formData.append("ShareholdingPattern", shareholdingPattern);
    formData.append("MOA", moa);
    formData.append("AOA", aoa);
    formData.append("CompanyTan", companyTan);
    setLoading(true); // Set loading to true when form submission starts

    // dispatch(creatBAnkaccount(formData, id));
    dispatch(creatBAnkaccount(formData, id))
      .then(() => {
        setLoading(false); // Set loading to false after submission
        navigate("/userDashboard");
      })
      .catch((error) => {
        setLoading(false); // Set loading to false if submission fails
        console.error("Failed to add payment:", error);
        alert("Failed to add BankAccount Inormation. Please try again.");
      });
  };
  const { Bankname } = location.state || {};
  const [bankLogo, setBankLogo] = useState(null);

  // Object mapping bank names to their respective logo URLs
  const bankLogos = {
    TD: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/t4bu4wzje3spb6cmrkb1.png",
    WELLSFARGO:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725534001/Wells_Fargo_Logo__2020_.svg_umkjpq.png",
    JP: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/napjigpkysj6wp8xrmlv.png",
    BOFA: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531681/mera%20bank/wuysfcffyefsu8eqoah7.webp",
    GOLDMAN:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/slftnh50pqkmm20facpq.png",
    HSBC: "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531680/mera%20bank/c7pctkstfqwn0gcsh3zt.png",
    DEUTSCHE:
      "https://res.cloudinary.com/dbibpsvgt/image/upload/v1725531698/mera%20bank/tmwykbmwcfm4uqm9psic.png",
  };
  useEffect(() => {
    if (Bankname && bankLogos[Bankname]) {
      setBankLogo(bankLogos[Bankname]);
    } else {
      setBankLogo(null); // Optionally handle when the bank logo is not found
    }
  }, [Bankname]);
  return (
    <div className="banking">
      <div className="headerr">
        <img src={logo} alt="VPVV" />
        <h3>VPVV TECHNO CONSTRUCTION PRIVATE LIMITED</h3>
        <h4 style={{ color: "#052040" }}>
          Global Corporate Bank Account Opening Form
        </h4>
      </div>
      <div className="container" style={{ height: "100%" }}>
        <div className="bankalls">
          {bankLogo ? (
            <img
              style={{ width: "11rem", borderRadius: "12px", margin: "1rem" }}
              src={bankLogo}
              alt={`${Bankname} Logo`}
            />
          ) : (
            "No bank logo available"
          )}

          <Form onSubmit={submithandler}>
            {/* Company Information */}
            <h5>Company Information</h5>
            <h6>Basic Details</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Date(Form Submission Date)</Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Name of Organization</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter organization name"
                    value={nameOfOrganisation}
                    onChange={(e) => setNameOfOrganisation(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Type of Organization</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g., Private Limited, LLC, etc."
                    value={typeOfOrganisation}
                    onChange={(e) => setTypeOfOrganisation(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Business Information</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Nature of Business/Industry</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g., Manufacturing, IT, etc."
                    value={natureOfBusiness}
                    onChange={(e) => setNatureOfBusiness(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Date of Establishment/Incorporation</Form.Label>
                  <Form.Control
                    type="date"
                    value={dateOfEstablishment}
                    onChange={(e) => setDateOfEstablishment(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country of Incorporation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter country of incorporation"
                    value={countryOfIncorporation}
                    onChange={(e) => setCountryOfIncorporation(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Registration Details</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Registration Number(Incorporation No.)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter company registration number"
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>TAN/TIN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter TIN or equivalent"
                    value={taxIdentificationNumber}
                    onChange={(e) => setTaxIdentificationNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>PAN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter PAN"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <h6>Address Information</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Registered Office Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter full registered address"
                    value={registeredOfficeAddress}
                    onChange={(e) => setRegisteredOfficeAddress(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>ZIP/Postal Code</Form.Label>
                  <Form.Control
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    {countries.map((country) => (
                      <option key={country}>{country}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Mailing Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter mailing address"
                    value={mailingAddress}
                    onChange={(e) => setMailingAddress(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      placeholder="Enter City"
                      value={mailingCity}
                      onChange={(e) => setMailingCity(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>ZIP/Postal Code</Form.Label>
                  <Form.Control
                    placeholder="Enter the Zip Code"
                    value={mailingZipCode}
                    onChange={(e) => setMailingZipCode(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={mailingCountry}
                    onChange={(e) => setMailingCountry(e.target.value)}
                    required
                  >
                    {countries.map((country) => (
                      <option key={country}>{country}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <h6>Contact Information</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Website (If available) </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter website URL"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Official Communication Email(Given By VPVV)
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter official email"
                    value={officialEmail}
                    onChange={(e) => setOfficialEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Row>
                    <Col xs={4}>
                      <Form.Control as="select">
                        {countryCodes.map((code) => (
                          <option key={code}>{code}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact number"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <h6>Politically Exposed Person Declaration</h6>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Is the person politically exposed?</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handlePoliticallyExposedChange}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {isPoliticallyExposed && (
                <Col>
                  <Form.Group>
                    <Form.Label>If Yes, Please Specify</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Does the signatory or any associate hold a position within a government institution/parastatal/entity?"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Enter their position"
                    />
                    <Form.Label>Attach Affidavit</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setAffidavit(e.target.files[0])}
                    />
                    <Form.Text className="text-muted">
                      By attaching this affidavit, you confirm that you
                      willingly allow us to block the account if the government
                      deems it necessary, and this account will not have any
                      transactions relating to the government.
                    </Form.Text>
                  </Form.Group>
                </Col>
              )}
            </Row>

            {/* Authorized Signatory Information */}
            {/* Authorized Signatory Information */}
            <h5>Authorized Signatory Information</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Number of Authorized Signatories</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleSignatoryCountChange}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {[...Array(numSignatories)]?.map((_, index) => (
              <div key={index}>
                <h6>Authorized Signatory {index + 1}</h6>
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter full name"
                        value={signatories[index].fullName} // Access from signatories array
                        onChange={(e) => {
                          const updatedSignatories = [...signatories];
                          updatedSignatories[index].fullName = e.target.value;
                          setSignatories(updatedSignatories);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={signatories[index]?.email} // Access from signatories array
                        onChange={(e) => {
                          const updatedSignatories = [...signatories];
                          updatedSignatories[index].email = e.target.value;
                          setSignatories(updatedSignatories);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Contact Number</Form.Label>
                      <Row>
                        <Col xs={4}>
                          <Form.Control as="select">
                            {countryCodes.map((code) => (
                              <option key={code}>{code}</option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            placeholder="Enter contact number"
                            value={signatories[index].contactNumber} // Access from signatories array
                            onChange={(e) => {
                              const updatedSignatories = [...signatories];
                              updatedSignatories[index].contactNumber =
                                e.target.value;
                              setSignatories(updatedSignatories);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Passport No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Passport No."
                          value={signatories[index].passport} // Access from nominees array
                          onChange={(e) => {
                            const updatedSignatories = [...signatories];
                            updatedSignatories[index].passport = e.target.value;
                            setSignatories(updatedSignatories);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      {" "}
                      <Form.Group>
                        <Form.Label>PAN</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="PAN"
                          value={signatories[index].pan} // Access from nominees array
                          onChange={(e) => {
                            const updatedSignatories = [...signatories];
                            updatedSignatories[index].pan = e.target.value;
                            setSignatories(updatedSignatories);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </div>
            ))}

            {/* Nominee Information */}
            <h5>Nominee Information</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Number of Nominees</Form.Label>
                  <Form.Control as="select" onChange={handleNomineeCountChange}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {[...Array(numNominees)]?.map((_, index) => (
              <div key={index}>
                <h6>Nominee {index + 1}</h6>
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter full name"
                        value={nominees[index]?.fullName} // Access from nominees array
                        onChange={(e) => {
                          const updatedNominees = [...nominees];
                          updatedNominees[index].fullName = e.target.value;
                          setNominees(updatedNominees);
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={nominees[index].email} // Access from nominees array
                        onChange={(e) => {
                          const updatedNominees = [...nominees];
                          updatedNominees[index].email = e.target.value;
                          setNominees(updatedNominees);
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Contact Number</Form.Label>
                      <Row>
                        <Col xs={4}>
                          <Form.Control as="select">
                            {countryCodes.map((code) => (
                              <option key={code}>{code}</option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            placeholder="Enter contact number"
                            value={nominees[index].contactNumber} // Access from nominees array
                            onChange={(e) => {
                              const updatedNominees = [...nominees];
                              updatedNominees[index].contactNumber =
                                e.target.value;
                              setNominees(updatedNominees);
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Passport No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Passport No."
                          value={nominees[index].passport} // Access from nominees array
                          onChange={(e) => {
                            const updatedNominees = [...nominees];
                            updatedNominees[index].passport = e.target.value;
                            setNominees(updatedNominees);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      {" "}
                      <Form.Group>
                        <Form.Label>PAN</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="PAN"
                          value={nominees[index].pan} // Access from nominees array
                          onChange={(e) => {
                            const updatedNominees = [...nominees];
                            updatedNominees[index].pan = e.target.value;
                            setNominees(updatedNominees);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </div>
            ))}

            {/* Account Preferences */}
            <h5>Account Preferences</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Account Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option>Business Account</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    as="select"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option>USD</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Initial Deposit Amount</Form.Label>
                  <Form.Control type="text" value="$100,000.00" readOnly />
                </Form.Group>
              </Col>
            </Row>

            {/* Services */}
            <h5>Services</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Internet Banking</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Request Internet Banking"
                    checked={internetBanking}
                    onChange={(e) => setInternetBanking(e.target.checked)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Debit Card</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Request Debit Card"
                    checked={debitCard}
                    onChange={(e) => setDebitCard(e.target.checked)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Document Uploads */}
            {/* Document Uploads */}
            <h5>Document Uploads</h5>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Certificate of Incorporation</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setIncorporationCertificate(e.target.files[0])
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Board Resolution</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setBoardResolution(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Company TAN</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setCompanyTan(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Address Proof (Registered Office)</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setAddressProof(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Authorized Signatory ID Proof</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setAuthorizedSignatoryIdProof(e.target.files[0])
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Shareholding Pattern</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setShareholdingPattern(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Memorandum of Association (MOA)</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setMoa(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Articles of Association (AOA)</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setAoa(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Company PAN</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setCompanyPan(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Terms & Conditions */}
            <h5>Declaration</h5>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="I/We hereby certify that all details entered in this form are accurate and complete to the best of my knowledge."
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                required
              />
            </Form.Group>

            {/* Submit Button */}
            <Button
              variant="primary"
              type="submit"
              className="form-submit-btn"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Finish"}{" "}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BankForm;
