import React from "react";
import "./directors.css";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

const AddDirectors = () => {
  const navigate = useNavigate();
  const handletoprofile = () => {
    navigate("/profile");
  };
  return (
    <>
      <div className="whole">
      <IoMdArrowBack className="corner-back" size={27} onClick={handletoprofile} />
      <button></button>
        <div className="director-heading">
          <h1>Add More Directors</h1>
          <hr />
          <div className="director-form">
          <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control type="name" placeholder="Enter Name" />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>
      </Row>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Profile Photo</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Document 1</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Document 2</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
      <button className="privews" type="submit">
        Add
      </button>
    
        </div>
        </div>
       
      </div>
    </>
  );
};

export default AddDirectors;
