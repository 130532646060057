import React, { useState, useEffect } from "react";
import "../otp/Otp.css";
import logo from "../logo-vpvv.png";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AdminAuthenticateCompanyOtp } from "../../redux/action/useravtion";
import UAParser from "ua-parser-js";  // Import UAParser

const AdminAuthenticateOtp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};
  const [otp, setOtp] = useState("");
  const [deviceInfo, setDeviceInfo] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize UAParser and extract device information
    const parser = new UAParser();
    const uaResult = parser.getResult();

    const device = {
      name: uaResult.browser.name || "Unknown Browser",
      version: uaResult.browser.version || "Unknown Version",
      os: uaResult.os.name || "Unknown OS",
      osVersion: uaResult.os.version || "Unknown OS Version",
      manufacturer: uaResult.device.vendor || "Unknown Device", // Get manufacturer (brand)
      model: uaResult.device.model || "Unknown Model"           // Get model of the device
    };

    setDeviceInfo(device);

    // Log the entire user agent string for debugging
    const userAgent = navigator.userAgent;
    
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    await dispatch(AdminAuthenticateCompanyOtp(email, otp, deviceInfo,navigate));
  };

  return (
    <div className="full">
      <img style={{ width: "13rem" }} src={logo} alt="logo" />
      <div className="box">
        <Row className="justify-content-center">
          <Col xs={10} md={6} className="containeraa">
            <h1 className="text-center mt-3">OTP Authentication</h1>
            <Form onSubmit={submitHandler}>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={otp}
                  placeholder="One Time Password"
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="otp">
                <button className="mt-4" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminAuthenticateOtp;
